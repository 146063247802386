import axios from "axios";
import TagManager from "react-gtm-module";
import * as types from "../constants";
import Toastr from "../../pages/misc/Toaster";
import { BACKEND_ENDPOINT, isValidPublicDomain } from "../../config";
import download from "downloadjs";

export const createNewList = (formData) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/campaign/createCampaign`;
    axios
      .post(
        reqURL,
        { ...formData },
        {
          headers: {
            authorization: `Bearer ${authorization[1].split(";")[0]}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: types.ADD_NEW_SUBSCRIBER_LIST,
          payload: { formData },
        });
        dispatch({
          type: types.ADD_NEW_SUBSCRIBER_LIST_SUCCESS,
          payload: res.status,
        });
        {
          isValidPublicDomain
            ? Toastr("Trackable Links created successfully.", "success")
            : Toastr("Campaign created successfully.", "success");
        }
        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.SET_ERRORS,
        });

        if (error.response) {
          dispatch({
            type: types.SET_ERRORS_SUCCESS,
            payload: error.response.data.message,
          });
          Toastr(error.response.data.message, "error");
        }
      });
  };
};
export const createShortUrl = (formData) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  const token = user && authorization[1].split(";")[0];
  let userType = token && JSON.parse(atob(token.split(".")[1]));
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/campaign/createSinglePurlPublic`;
    axios
      .post(
        reqURL,
        { ...formData },
        {
          headers: {
            authorization: `Bearer ${authorization[1].split(";")[0]}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: types.SHORT_URL,
          payload: res.data.shorturl,
        });
        dispatch({
          type: types.LONG_URL,
          payload: res.data.long_url,
        });
        dispatch({
          type: types.ADD_NEW_SHORT_URL_SUCCESS,
          payload: res.status,
        });
        dispatch({
          type: types.OPEN_SHORT_URL_MODAL,
          payload: true,
        });
        Toastr("Short url created successfully.", "success");

        // ---------GTM Start----------
        const tagManagerArgs = {
          gtmId: "GTM-PSTCZS3",
          dataLayer: {
            event: "formSubmission",
            formName: "Create new short url",
            userId: userType && userType.id,
          },
        };
        TagManager.initialize(tagManagerArgs);
        // --------- GTM End-------------
      })
      .catch((error) => {
        if (error.response) {
          Toastr(error.response.data.message, "error");
        }
      });
  };
};
export const createLinkInBio = (formData) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=")[1].split(";")[0];
  let userType = authorization && JSON.parse(atob(authorization.split(".")[1]));

  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/campaign/createLinkInBio`;

    axios
      .post(reqURL, formData, {
        headers: {
          Authorization: `Bearer ${authorization}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({
          type: types.LINK_IN_BIO,
          payload: res.data.genrated_shorturl,
        });
        dispatch({
          type: types.ADD_NEW_LINK_IN_BIO_SUCCESS,
          payload: res.status,
        });
        dispatch({
          type: types.OPEN_LINK_IN_BIO_MODAL,
          payload: true,
        });
        Toastr("Link created successfully.", "success");

        // ---------GTM Start----------
        const tagManagerArgs = {
          gtmId: "GTM-PSTCZS3",
          dataLayer: {
            event: "formSubmission",
            formName: "Create new link in bio",
            userId: userType && userType.id,
          },
        };
        TagManager.initialize(tagManagerArgs);
        // --------- GTM End-------------
      })
      .catch((error) => {
        if (error.response) {
          Toastr(error.response.data.message, "error");
        }
      });
  };
};
export const emptyAddNewShortUrlStatus = () => {
  return (dispatch) => {
    dispatch({
      type: types.ADD_NEW_LINK_IN_BIO_SUCCESS,
      payload: null,
    });
  };
};
export const emptyAddNewLinkInBioStatus = () => {
  return (dispatch) => {
    dispatch({
      type: types.ADD_NEW_SHORT_URL_SUCCESS,
      payload: null,
    });
  };
};
export const emptyShortUrlModalOpenState = () => {
  return (dispatch) => {
    dispatch({
      type: types.OPEN_SHORT_URL_MODAL,
      payload: false,
    });
  };
};
export const emptyLinkInBioModalOpenState = () => {
  return (dispatch) => {
    dispatch({
      type: types.OPEN_LINK_IN_BIO_MODAL,
      payload: false,
    });
  };
};
export const emptyDraftStatus = () => {
  return (dispatch) => {
    dispatch({
      type: types.ADD_NEW_DRAFT,
      payload: null,
    });
  };
};
export const draftACampaign = (draftData) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/campaign/draftCampaign`;
    axios
      .post(
        reqURL,
        { ...draftData },
        {
          headers: {
            authorization: `Bearer ${authorization[1].split(";")[0]}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: types.ADD_NEW_DRAFT,
          payload: res.data.status,
        });
        Toastr("Saved as Draft", "success");
      })
      .catch((error) => {
        Toastr(error.response.data.message, "error");
      });
  };
};
export const updateDraftedCampaign = (draftData) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");

  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/campaign/draftCampaign`;
    axios
      .post(
        reqURL,
        { ...draftData },
        {
          headers: {
            authorization: `Bearer ${authorization[1].split(";")[0]}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: types.UPDATE_A_DRAFT,
          payload: res.data.status,
        });
        Toastr("Campaign Drafted successfully.", "success");
      })
      .catch((error) => {
        Toastr(error.response.data.message, "error");
      });
  };
};

export const getSignedUrls = (metaData) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/campaign/generateSignedUrl`;
    axios
      .post(reqURL, metaData, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.GET_SIGNED_URLS,
          payload: res.data,
        });
        dispatch({
          type: types.GET_SIGNED_URLS_SUCCESS,
        });
        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.SET_ERRORS,
        });
        if (error.response) {
          dispatch({
            type: types.SET_ERRORS_SUCCESS,
            payload: error.response.data.message,
          });
          Toastr(error.response.data.message, "error");
        }
      });
  };
};

export const emptySignedUrls = () => {
  return (dispatch) => {
    dispatch({
      type: types.EMPTY_SIGNED_URLS,
      payload: [],
    });
  };
};
export const uploadOnSignedUrl = (files) => {
  try {
    const promises = files.map((fileData) => {
      const config = {
        headers: {
          "Content-Type": "application/octet-stream",
        },
        skipInterceptor: true,
      };

      return axios.put(fileData?.signedUrl, fileData?.file, config);
    });

    return (dispatch) => {
      Promise.allSettled(promises)
        .then((results) => {
          let isSuccess = true;
          results.forEach((result) => {
            if (result.status === "rejected") {
              isSuccess = false;
            }
          });
          if (isSuccess) {
            // dispatch action for successful upload
            dispatch({
              type: types.UPLOAD_FILES,
            });
            dispatch({
              type: types.UPLOAD_FILES_SUCCESS,
            });
            Toastr("File upload successful!", "success");
            return true;
          } else {
            // dispatch action for upload failure
            dispatch({
              type: types.UPLOAD_ERROR,
            });
            dispatch({
              type: types.UPLOAD_ERROR_SUCCESS,
            });
            Toastr(
              "Some error occurred while uploading. Please try again later",
              "error"
            );
          }
        })
        .catch((error) => {
          console.error(`Error uploading files: ${error}`);
          // dispatch action for upload failure
        });
    };
  } catch (error) {
    return (dispatch) => {
      if (error.message === "Network Error") {
        dispatch({
          type: types.UPLOAD_ERROR,
        });
        dispatch({
          type: types.UPLOAD_ERROR_SUCCESS,
        });
        Toastr(
          "Some error occurred while uploading. Please try again later",
          "error"
        );
      } else {
        throw error;
      }
    };
  }
};

export const uploadFiles = (files) => {
  return async (dispatch) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const authorization = user.authorization.split("=");
      const reqURL = `${BACKEND_ENDPOINT}/campaign/campaign-file-upload`;
      const maxFileSizeDirectUpload = 32 * 1024 * 1024; // 32MB
      let shouldUploadInChunks = false;
      let totalFileSize = 0;

      dispatch({
        type: types.FILE_UPLOAD_START,
      });

      // Calculate the total size of all the files and check if any file exceeds the max file size
      for (let i = 0; i < files.length; i++) {
        const fileSize = files[i].size;
        totalFileSize += fileSize;
        if (totalFileSize > maxFileSizeDirectUpload) {
          shouldUploadInChunks = true;
        }
      }

      // Initialize the Axios instance
      const axiosInstance = axios.create();

      // Set the Content-Type header to multipart/form-data
      const headers = {
        "Content-Type": "multipart/form-data",
        authorization: `Bearer ${authorization[1].split(";")[0]}`,
      };

      if (!shouldUploadInChunks) {
        // If none of the files exceed the max file size, upload them directly using a single POST request
        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
          formData.append(`files`, files[i]);
        }

        let response = await axiosInstance.post(reqURL, formData, { headers });
        dispatch({
          type: types.GET_SIGNED_URLS,
          payload: response.data,
        });
        dispatch({
          type: types.GET_SIGNED_URLS_SUCCESS,
        });
        dispatch({
          type: types.UPLOAD_FILES_SUCCESS,
        });
        Toastr("File upload successful!", "success");
      } else {
        // If any of the files exceed the max file size, split each file into chunks of 10MB and upload each chunk separately using a POST request
        const chunkSize = 30 * 1024 * 1024; // 30MB

        // Loop through each file and split it into chunks if needed

        for (let i = 0; i < files.length; i++) {
          let uploadedChunks = 1;
          let totalChunks = 0;
          const file = files[i];
          const fileSize = file.size;

          if (fileSize <= maxFileSizeDirectUpload) {
            // Upload the file directly if it's smaller than the max file size
            const formData = new FormData();
            formData.append(`files`, file);

            let response = await axiosInstance.post(reqURL, formData, {
              headers,
            });
            dispatch({
              type: types.GET_SIGNED_URLS,
              payload: response.data,
            });
            if (i === files.length - 1) {
              dispatch({
                type: types.GET_SIGNED_URLS_SUCCESS,
              });
              dispatch({
                type: types.UPLOAD_FILES_SUCCESS,
              });
              Toastr("File upload successful!", "success");
            }
          } else {
            // Split the file into chunks and upload each chunk separately
            const chunks = Math.ceil(fileSize / chunkSize);
            const chunksArray = [];

            for (let j = 0; j < chunks; j++) {
              const start = j * chunkSize;
              const end = Math.min(start + chunkSize, fileSize);
              const chunk = file.slice(start, end);
              chunksArray.push({
                chunk,
                filename: file.name,
                chunkNumber: j,
                totalChunks: chunks,
                size: end - start,
              });
            }

            // Sort the chunks based on their size
            chunksArray.sort((a, b) => a.size - b.size);

            // const chunks = Math.ceil(fileSize / chunkSize);
            totalChunks += chunks;

            for (let k = 0; k < chunksArray.length; k++) {
              const chunkData = chunksArray[k];

              const formData = new FormData();
              formData.append("files", chunkData.chunk);
              formData.append("filename", chunkData.filename);
              formData.append("chunkIndex", uploadedChunks);
              formData.append("totalChunkCount", totalChunks);

              // Set the Content-Range header to indicate which part of the file you are sending
              const config = {
                headers: {
                  ...headers,
                  // 'Content-Range': `bytes ${start}-${end}/${fileSize}`,
                },
              };

              // Send the chunk to the server using a POST request
              let response = await axiosInstance.post(reqURL, formData, config);
              dispatch({
                type: types.GET_SIGNED_URLS,
                payload: response.data,
              });
              if (i === files.length - 1) {
                if (k === chunksArray.length - 1) {
                  dispatch({
                    type: types.GET_SIGNED_URLS_SUCCESS,
                  });
                  dispatch({
                    type: types.UPLOAD_FILES_SUCCESS,
                  });
                  Toastr("File upload successful!", "success");
                }
              }
              uploadedChunks++;
            }
          }
        }
      }
    } catch (error) {
      dispatch({
        type: types.UPLOAD_ERROR,
      });
      dispatch({
        type: types.UPLOAD_ERROR_SUCCESS,
      });
      if (error?.response) {
        Toastr(error?.response?.data?.message, "error");
      } else {
        Toastr(
          "Some error occurred while uploading. Please try again later",
          "error"
        );
      }
    }
  };
};

export const editList = (formData) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqUrl = `${BACKEND_ENDPOINT}/campaign`;
    axios
      .put(reqUrl, formData, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.EDIT_SUBSCRIBER_LIST,
          payload: { formData },
        });
        dispatch({
          type: types.EDIT_SUBSCRIBER_LIST_SUCCESS,
          payload: res.status,
        });
        Toastr("Campaign updated successfully.", "success");
        return true;
      })
      .catch((err) => {
        if (err.response) {
          Toastr(err.response.data.message, "error");
          return { error: err };
        }
      });
  };
};

export const getShortDomain = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    dispatch({ type: types.LOAD });
    const reqUrl = `${BACKEND_ENDPOINT}/short-domain`;

    axios
      .get(reqUrl, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.GET_SHORT_DOMAIN, payload: res.data });
        dispatch({ type: types.GET_SHORT_DOMAIN_SUCCESS });
        return res;
      })
      .catch(() => {});
  };
};

export const downloadList = async (id, type, name) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  const reqUrl = `${BACKEND_ENDPOINT}/campaign/download?id=${id}&type=${type}`;
  axios
    .get(reqUrl, {
      headers: {
        authorization: `Bearer ${authorization[1].split(";")[0]}`,
      },
      responseType: "blob",
    })
    .then((res) => {
      const file = new Blob([res.data], { type: "application/zip" });
      if (type === "input") {
        download(file, `Input-${name}.zip`);
      } else {
        download(file, `Output-${name}.zip`);
      }
      Toastr(
        "Your file has been downloaded successfully. Thank you.",
        "Success"
      );
    })
    .catch((error) => {
      if (error.response) {
        Toastr(error.response.data.message, "error");
      }
    });
};
export const copyCampaign = (id) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");

  return async (dispatch) => {
    const reqUrl = `${BACKEND_ENDPOINT}/campaign/copyCampaign`;
    await axios
      .post(
        reqUrl,
        { campaign_id: id },
        {
          headers: {
            authorization: `Bearer ${authorization[1].split(";")[0]}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: types.COPY_CAMPAIGN_ID,
          payload: [res.data.campaign_id],
        });
        Toastr("Campaign Copied successfully.", "success");
      })
      .catch((error) => {
        Toastr(error.response.data.message, "error");
      });
  };
};
export const campaignStatus = (uploadData) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return async (dispatch) => {
    const reqUrl = `${BACKEND_ENDPOINT}/campaign/draftCampaign`;
    await axios
      .put(
        reqUrl,
        { ...uploadData },
        {
          headers: {
            authorization: `Bearer ${authorization[1].split(";")[0]}`,
          },
        }
      )
      .then(() => {
        if (uploadData?.status) {
          {
            isValidPublicDomain
              ? Toastr("Trackable Links created successfully.", "success")
              : Toastr("Campaign created successfully.", "success");
          }
        } else {
          {
            isValidPublicDomain
              ? Toastr("Trackable Links updated successfully.", "success")
              : Toastr("Campaign updated successfully.", "success");
          }
        }
        dispatch({
          type: types.SAVE_CAMPAIGN_STATUS,
          payload: true,
        });
      })

      .catch((error) => {
        Toastr(error.response.data.message, "error");
      });
  };
};
export const emptyCampaignStatus = () => {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_CAMPAIGN_STATUS,
      payload: false,
    });
  };
};
export const downloadTemplate = async () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  const reqUrl = `${BACKEND_ENDPOINT}/campaign/download-template`;
  axios
    .get(reqUrl, {
      headers: {
        authorization: `Bearer ${authorization[1].split(";")[0]}`,
      },
      responseType: "blob",
    })
    .then((res) => {
      const file = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      download(file, `purl-campaign-template.xlsx`);
    })
    .catch(() => {});
};

export const getAllTag = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return async (dispatch) => {
    dispatch({ type: types.LOAD });
    const reqUrl = `${BACKEND_ENDPOINT}/tag`;
    await axios
      .get(reqUrl, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.GET_ALL_TAGS, payload: res.data });
      })
      .catch(() => {});
  };
};

export const fetchSubscriberLists = (query) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  let val = {};
  let reqURL;
  if (query !== "true") {
    reqURL = `${BACKEND_ENDPOINT}/campaign?delete=false`;
    val.type = types.FETCH_SUBSCRIBER_LIST;
  } else {
    reqURL = `${BACKEND_ENDPOINT}/campaign?delete=false`;
    val.type = types.REPORT_SUBSCRIBER_LIST;
  }
  return (dispatch) => {
    dispatch({ type: types.LOAD_SUBSCRIBER_LIST, payload: true });

    axios
      .get(reqURL, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        val.payload = res.data;
        dispatch(val);
        val.type = `${val.type}_SUCCESS`;
        dispatch(val);
      })
      .catch(function () {
        dispatch({ type: types.SUBSCRIBER_LIST_ERROR, payload: true });
      });
  };
};

export const deleteSubscriberList = (sub_list_id) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    dispatch({ type: types.LOAD_SUBSCRIBER_LIST, payload: true });
    const reqURL = `${BACKEND_ENDPOINT}/campaign/delete?campaign_id=${sub_list_id}`;
    axios
      .delete(reqURL, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.DELETE_SUBSCRIBER_LIST, payload: res.data });
        Toastr(
          `${res.data.subscriber.campaign_name} ${res.data.message}`,
          "success"
        );
        dispatch({ type: types.DELETE_SUBSCRIBER_LIST_SUCCESS });
      })
      .catch((err) => {
        if (err.response) {
          Toastr(err.response.data.message, "error");
        }
      });
  };
};

export const formSubmitSuccess = () => {
  return (dispatch) => {
    dispatch({ type: types.FORM_SUBMIT_SUCCESS });
  };
};

export const singleCampaignData = (id) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  const reqURL = `${BACKEND_ENDPOINT}/campaign/${id}`;
  return (dispatch) => {
    axios
      .get(reqURL, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.FETCH_SINGLE_CAMPAIGN_DATA, payload: res.data });
      })
      .catch((err) => {
        if (err.response) {
          Toastr(err.response.data.message, "error");
          return { error: err };
        }
      });
  };
};

export const fetchSingleReportData = (start_date, end_date, id) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/report/singleCampaignClicks?start_date=${start_date}&end_date=${end_date}&campaign_id=${id}`;
    axios
      .get(reqURL, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.SINGLE_REPORT_CHART_DATA, payload: res.data });
        dispatch({
          type: types.SINGLE_CAMPAIGN_REFRESH_STOP,
        });
      })
      .catch((err) => {
        if (err.response) {
          Toastr(err.response.data.message, "error");
          return { error: err };
        }
      });
  };
};

export const fetchTableData = (id, start_date, end_date, type) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/report/campaignPurls?campaign_id=${id}&start_date=${start_date}&end_date=${end_date}&type=${type}`;
    axios
      .get(reqURL, {
        headers: { authorization: `Bearer ${authorization[1].split(";")[0]}` },
      })
      .then((res) => {
        dispatch({ type: types.FETCH_TABLE_DATA, payload: res.data });
        dispatch({ type: types.FETCH_TABLE_DATA_LOADING });
        dispatch({
          type: types.SINGLE_CAMPAIGN_REFRESH_STOP,
        });
      })
      .catch((err) => {
        if (err.response) {
          Toastr(err.response.data.message, "error");
          return { error: err };
        }
      });
  };
};

export const downloadTableData = (id, start_date, end_date, type, name) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return () => {
    const reqURL = `${BACKEND_ENDPOINT}/report/downloadCampaignPurls?campaign_id=${id}&start_date=${start_date}&end_date=${end_date}&type=${type}`;
    axios
      .get(reqURL, {
        headers: { authorization: `Bearer ${authorization[1].split(";")[0]}` },
        responseType: "blob",
      })
      .then((res) => {
        const file = new Blob([res.data], { type: "application/zip" });
        if (type === "clicked") {
          download(file, `${name}-report-clicked.zip`);
          Toastr("File downloaded successfully. Thank you.", "Success");
        } else if (type === "unclicked") {
          download(file, `${name}-report-unclicked.zip`);
          Toastr("File downloaded successfully. Thank you.", "Success");
        } else {
          download(file, `${name}-report-all-clicked.zip`);
          Toastr("File downloaded successfully. Thank you.", "Success");
        }
      })
      .catch((err) => {
        if (err.response) {
          Toastr(err.response.data.message, "error");
          return { error: err };
        }
      });
  };
};

export const refresh_data = () => {
  return (dispatch) => {
    axios;
    dispatch({
      type: types.SINGLE_CAMPAIGN_REFRESH_DATA,
    });
  };
};

//GET DROPDOWN ALL CAMPAIGN DATA
export const getAllCampaignDropdownData = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqUrl = `${BACKEND_ENDPOINT}/campaign/allCampaignClicks`;
    axios
      .get(reqUrl, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.GET_CAMPAIGNS_DROPDOWN, payload: res.data });
        dispatch({ type: types.GET_CAMPAIGNS_DROPDOWN_SUCCESS });
        return res;
      })
      .catch(() => {});
  };
};

// RETARGET CAMPAIGN DATA
export const retargetCampaign = (retargetData) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    dispatch({ type: types.RETARGET_CAMPAIGN_LOADER });
    const reqUrl = `${BACKEND_ENDPOINT}/campaign/retarget/${retargetData?.retargetType}`;
    axios
      .post(reqUrl, retargetData, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.RETARGET_CAMPAIGN, payload: res.data });
        dispatch({ type: types.RETARGET_CAMPAIGN_SUCCESS });
        dispatch({
          type: types.RETARGET_CAMPAIGN_PARENT_ID,
          payload: retargetData.campaign_id,
        });
        return res;
      })
      .catch((err) => {
        if (err && err.response) {
          Toastr(err.response.data.message, "error");
          dispatch({ type: types.RETARGET_CAMPAIGN_SUCCESS });
          return { error: err };
        } else {
          Toastr("An error occurred. Please try again.", "error");
          dispatch({ type: types.RETARGET_CAMPAIGN_SUCCESS });
        }
      });
  };
};
export const getTeams = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    dispatch({ type: types.LOAD });
    const reqUrl = `${BACKEND_ENDPOINT}/team`;

    axios
      .get(reqUrl, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.GET_TEAMS, payload: res.data });
        dispatch({ type: types.GET_TEAMS_SUCCESS });
        return res;
      })
      .catch(() => {});
  };
};

export const getTableData = (setLoading) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqUrl = `${BACKEND_ENDPOINT}/campaign/getClicksTablePublic?mini_clicks=true`;
    setLoading(true);
    axios
      .get(reqUrl, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.GET_TABLE_DATA, payload: res.data });
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          Toastr(err.response.data.message, "error");
          setLoading(false);
        }
      });
  };
};

export const setTabState = () => {
  return (dispatch) => {
    dispatch({ type: types.TABSTATES });
  };
};

export const checkPackagesValidity = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqUrl = `${BACKEND_ENDPOINT}/campaign/getFeatureLimits`;
    axios
      .get(reqUrl, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.CHECK_PACKAGE_VALIDITY, payload: res.data });
      })
      .catch((err) => {
        if (err.response) {
          Toastr(err.response.data.message, "error");
        }
      });
  };
};
export const userPackageIsExpired = (isExpired) => {
  return (dispatch) => {
    dispatch({
      type: types.USER_PACKAGE_EXPIRY,
      payload: isExpired,
    });
  };
};
