import * as types from "../constants";

const initialState = {
  role: [],
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.FETCH_ROLE:
      return {
        ...state,
        role: actions.payload,
      };
    default:
      return state;
  }
}
