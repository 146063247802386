import React from "react";
import { Modal, Button, ModalHeader } from "reactstrap";
import { X } from "react-feather";
import { isValidPublicDomain } from "../../config";

const DraftModal = (props) => {
  const { toggle, handleUpload, handleCampaignDraft } = props;

  return (
    <>
      <Modal
        {...props}
        toggle={() => {
          toggle();
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="draft-modal"
        style={{ maxWidth: "450px" }}
      >
        <ModalHeader className="px-4 d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-end ">
            <Button
              color="none"
              onClick={toggle}
              style={{
                color: "black",
                fontSize: "20px",
                border: "none",
                outline: "none",
              }}
            >
              <X />
            </Button>{" "}
          </div>

          <h5
            className="font-weight-light mb-0"
            style={{ fontSize: "20px", textAlign: "center" }}
          >
            Are you sure you want to Publish{" "}
            {isValidPublicDomain ? "the trackable links" : "this campaign"}?
          </h5>
          <div className="d-flex justify-content-center mt-4 mb-4">
            <Button
              color="primary"
              size="lg"
              className="mr-3 px-4"
              style={{ minWidth: "120px" }}
              onClick={handleUpload}
            >
              Yes
            </Button>
            <Button
              color="primary"
              size="lg"
              className="px-4 bg-white text-primary border border-primary"
              style={{ minWidth: "120px" }}
              onClick={handleCampaignDraft}
            >
              No, Save it as Draft
            </Button>
          </div>
        </ModalHeader>
      </Modal>
    </>
  );
};

export default DraftModal;
