import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";
import Select from "react-select";
import styled from "styled-components";
import { Redirect, useHistory } from "react-router-dom";
import { Eye, EyeSlash } from "@styled-icons/bootstrap";
import { customStyles, errorStyles } from "../../components/CustomStyle";
import { countryCodes } from "../../components/countryCodeObject";
import { BACKEND_ENDPOINT, purlPackages } from "../../config";
import { ArrowRight, ArrowLeft } from "react-feather";
import {
  GetStartedFormValidation,
  GetStartedSecondForm,
} from "../validation/validation";
import {
  GetStartedAction,
  emptyPurlPackage,
  validateUserPayment,
  setCouponCode,
  redirectToPaymentPortal,
} from "../../redux/actions/authActions";

import FreeTrialPromoCode from "../../components/FreeTrialCouponModal";

import Toastr from "../misc/Toaster";

const GetStarted = ({ GetStartedAction, setCouponCode, packageValidity }) => {
  const history = useHistory();

  const [freeTrialModal, setFreeTrialModal] = useState(false);

  const toggleFreeTrialModal = () => {
    setFreeTrialModal(!freeTrialModal);
  };
  //form 1 useStates
  const [matchedPackage, setMatchedPackage] = useState(null);
  const [queryPackage, setQueryPackage] = useState({
    plan: null,
    amount: null,
    coupon: null,
  });

  const [loading, setLoading] = useState(false);
  const [functionCompleted, setFunctionCompleted] = useState(null);
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedUses, setSelectedUses] = useState(null);
  const [purlUses, setPurlUses] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const [errors, setErrors] = useState();
  //form 2 useStates
  const [companyName, setCompanyName] = useState("");
  const [industry, setIndustry] = useState("");
  const [role, setRole] = useState("");

  const [step, setStep] = useState(1);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  let tokenData = JSON.parse(localStorage.getItem("temp_data"));
  const local_user = JSON.parse(localStorage.getItem("user"));

  let longUrl = "";
  let linkName = "";
  if (window.location.search) {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.size > 0) {
      const queryLinkName = queryParams.get("link_name");
      const queryLongUrl = queryParams.get("long_url");
      if (queryLinkName && queryLongUrl) {
        let longURL = window.location.search.split("&link_name");
        longUrl = longURL[0].split("?long_url=")[1];
        let fetched_link_name = window.location.search.split("&link_name=")[1];
        linkName = fetched_link_name.replace(/%20/g, " ");
      }
    }
  }
  const stepOneSubmit = () => {
    setErrors([]);
    const { formIsValid, error } = GetStartedFormValidation({
      field_name: name,
      field_country: country,
      field_phone: phoneNumber,
      field_email: email,
      field_confirm_email: confirmEmail,
      field_password: password,
      // field_use: selectedUses,
    });
    setErrors(error);
    if (formIsValid) {
      userExists(email);
    }
  };

  const userExists = async (email) => {
    try {
      const response = await axios.get(
        `${BACKEND_ENDPOINT}/user/emailExists?email=${email}`,
        {}
      );
      if (!response.data.user_exists) {
        localStorage.setItem(
          "temp_data",
          JSON.stringify({
            full_name: name,
            email: email,
            password: password,
            phone: phoneNumber,
            purl_use_id: selectedUses,
            long_url: longUrl,
            link_name: linkName,
            country: country,
          })
        );
        setStep(2);
      } else if (response.data.user_exists) {
        Toastr(
          "This email already exists. Please try a different one.",
          "error"
        );
      }
    } catch (error) {
      Toastr(error.message, "error");
    }
  };

  useEffect(() => {
    if (step === 1) {
      setLoading(true);
      const getPurlUses = async () => {
        try {
          const response = await axios.get(
            `${BACKEND_ENDPOINT}/auth/getPurlUses`,
            {}
          );
          setPurlUses(
            response.data.map((item) => ({
              value: item.purl_use_id,
              label: item.purl_use_title,
            }))
          );
        } catch (error) {
          Toastr(error.message, "error");
        } finally {
          setLoading(false);
        }
      };
      getPurlUses();
    }

    if (tokenData) {
      setName(tokenData?.full_name);
      setPhoneNumber(tokenData?.phone);
      setEmail(tokenData?.email);
      setConfirmEmail(tokenData?.email);
      setPassword(tokenData?.password);
      setSelectedUses(tokenData?.purl_use_id);
      setCountry(tokenData?.country);
    }
  }, []);

  useEffect(() => {
    if (window.location.search) {
      const queryParams = new URLSearchParams(window.location.search);

      if (queryParams.size > 0) {
        const plan = queryParams.get("plan");
        const amount = queryParams.get("amount");
        const coupon = queryParams.get("couponCode");
        if (plan && amount) {
          setQueryPackage({
            plan: plan,
            amount: amount,
            coupon: coupon,
          });
        } else {
          setFunctionCompleted(false);
        }
      }
    } else {
      setFunctionCompleted(false);
    }
  }, []);

  useEffect(() => {
    const matchPackage = () => {
      if (queryPackage.plan && queryPackage.amount) {
        const matched = purlPackages.find(
          (pkg) =>
            pkg.plan === queryPackage.plan && pkg.amount === queryPackage.amount
        );
        if (matched) {
          setMatchedPackage(matched);
          setFunctionCompleted(true);
        } else {
          Toastr("Invalid package", "error");
          setMatchedPackage(null);
        }
      }
    };
    matchPackage();
  }, [queryPackage]);

  useEffect(() => {
    const performValidationAndRedirect = async () => {
      if (functionCompleted !== null) {
        if (matchedPackage && functionCompleted && local_user) {
          try {
            history.push(
              `/links?plan=${matchedPackage?.plan}&amount=${matchedPackage?.amount}`
            );
          } catch (error) {
            console.error("Failed to validate payment", error);
          }
        } else if (local_user && functionCompleted === false) {
          if (longUrl && linkName) {
            history.push(
              `/campaigns/create-purls?long_url=${longUrl}&link_name=${linkName}`
            );
          } else {
            history.push("/links");
          }
        }
      }
    };

    performValidationAndRedirect();
  }, [functionCompleted]);

  useEffect(() => {
    if (queryPackage?.coupon) {
      setCouponCode(queryPackage?.coupon);
    }
  }, [queryPackage.coupon]);
  const sendData = () => {
    const data = {
      full_name: tokenData?.full_name,
      email: tokenData?.email,
      password: tokenData?.password,
      phone: tokenData?.country.concat(tokenData?.phone),
      purl_use_id: tokenData?.purl_use_id,
      industry,
      company: companyName,
      industry_role: role,
      long_url: longUrl,
      link_name: linkName,
      payment_plan: matchedPackage?.plan,
    };

    setErrors([]);
    const { formIsValid, error } = GetStartedSecondForm({
      company: companyName,
      industry,
      role,
    });
    setErrors(error);
    if (formIsValid) {
      if (matchedPackage) {
        toggleFreeTrialModal();
      } else {
        GetStartedAction(data, history, setStep);
      }
    }
  };

  if (step === 1) {
    return (
      <div className="mb-4">
        {loading ? (
          <CardBody
            className="d-flex justify-content-center"
            style={{ marginBlock: "200px" }}
          >
            <Spinner
              animation="border"
              color="primary"
              role="status"
              style={{ width: "8rem", height: "8rem", borderWidth: "10px" }}
            />
          </CardBody>
        ) : (
          <div className="mt-3">
            <div>
              <p className="h3 mb-2 page-headers-1 text-dark text-center">
                Get Started
              </p>
              <p className="lead mt-2 text-center">
                Start creating the best possible user experience for your
                customers
              </p>
            </div>
            <Card className="mb-0">
              <CardBody>
                <div>
                  <Form>
                    <FormGroup>
                      <Label style={{ fontSize: "14px" }}>
                        Name<span className="text-danger">*</span>
                      </Label>
                      <Input
                        bsSize="lg"
                        type="name"
                        name="name"
                        id="name"
                        style={errors?.name ? { border: "1px solid red" } : {}}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            name: "",
                          }));
                        }}
                        placeholder="Enter your full name"
                      />
                      {errors?.name && (
                        <p style={{ color: "red" }} className="mb-0">
                          {errors?.name}
                        </p>
                      )}
                    </FormGroup>
                    <div
                      className="d-sm-flex justify-content-between"
                      style={{ gap: "20px" }}
                    >
                      <FormGroup className="w-100 mb-2 mb-sm-0">
                        <Label style={{ fontSize: "14px" }}>
                          Country<span className="text-danger">*</span>
                        </Label>
                        <Select
                          className="react-select-container w-100 mb-0"
                          isSearchable={true}
                          placeholder="Select Country"
                          styles={errors?.country ? errorStyles : customStyles}
                          options={countryCodes}
                          value={
                            country
                              ? countryCodes.find((e) => e.value == country)
                              : ""
                          }
                          onChange={(e) => {
                            setCountry(e.value);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              country: "",
                            }));
                          }}
                        />
                        {errors?.country && (
                          <p style={{ color: "red" }} className="mb-0">
                            {errors?.country}
                          </p>
                        )}
                      </FormGroup>
                      <FormGroup className="w-100">
                        <Label style={{ fontSize: "14px" }}>
                          Phone<span className="text-danger">*</span>
                        </Label>
                        <Input
                          bsSize="lg"
                          type="tel"
                          name="phone"
                          id="phone"
                          className="w-100 custom-input"
                          style={
                            errors?.phone ? { border: "1px solid red" } : {}
                          }
                          value={phoneNumber}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            setPhoneNumber(numericValue);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              phone: "",
                            }));
                          }}
                          placeholder="Enter your phone number"
                        />
                        {errors?.phone && (
                          <p style={{ color: "red" }} className="mb-0">
                            {errors?.phone}
                          </p>
                        )}
                      </FormGroup>
                    </div>
                    <FormGroup>
                      <Label style={{ fontSize: "14px" }}>
                        Email<span className="text-danger">*</span>
                      </Label>
                      <Input
                        bsSize="lg"
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            email: "",
                          }));
                        }}
                        style={errors?.email ? { border: "1px solid red" } : {}}
                        placeholder="Enter your email"
                      />
                      {errors?.email && (
                        <p style={{ color: "red" }} className="mb-0">
                          {errors?.email}
                        </p>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label style={{ fontSize: "14px" }}>
                        Confirm Email<span className="text-danger">*</span>
                      </Label>
                      <Input
                        bsSize="lg"
                        type="email"
                        name="confirmEmail"
                        id="confirmEmail"
                        value={confirmEmail}
                        onChange={(e) => {
                          setConfirmEmail(e.target.value);
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            confirmEmail: "",
                          }));
                        }}
                        style={
                          errors?.confirmEmail
                            ? { border: "1px solid red" }
                            : {}
                        }
                        placeholder="Enter your email"
                      />
                      {errors?.confirmEmail && (
                        <p style={{ color: "red" }} className="mb-0">
                          {errors?.confirmEmail}
                        </p>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <Label style={{ fontSize: "14px" }}>
                        Password<span className="text-danger">*</span>
                      </Label>
                      <div className="d-flex">
                        <Input
                          bsSize="lg"
                          type={passwordShown ? "text" : "password"}
                          name="password"
                          id="password"
                          value={password}
                          style={{
                            paddingRight: "40px",
                            ...(errors?.password
                              ? { border: "1px solid red" }
                              : {}),
                          }}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              password: "",
                            }));
                          }}
                          placeholder="Set a password"
                        />
                        {!passwordShown ? (
                          <Noeye onClick={togglePasswordVisiblity} />
                        ) : (
                          <Showeye onClick={togglePasswordVisiblity} />
                        )}
                      </div>
                      {errors?.password && (
                        <p style={{ color: "red" }} className="mb-0">
                          {errors?.password}
                        </p>
                      )}
                    </FormGroup>
                    {/* <FormGroup className="mb-2">
                      <Label style={{ fontSize: "14px" }}>
                        How would you like to use purl primarily
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        className="react-select-container w-100"
                        isSearchable={true}
                        styles={errors?.use ? errorStyles : customStyles}
                        options={purlUses}
                        value={
                          selectedUses
                            ? purlUses.find((e) => e.value == selectedUses)
                            : ""
                        }
                        onChange={(e) => {
                          setSelectedUses(e.value);
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            use: "",
                          }));
                        }}
                      />
                      {errors?.use && (
                        <p style={{ color: "red" }} className="mb-0">
                          {errors?.use}
                        </p>
                      )}
                    </FormGroup> */}
                    {longUrl ? (
                      <div className="text-center mt-3 mb-4 ">
                        <Button
                          className="py-2 mt-3"
                          color="primary"
                          size="lg"
                          style={{ paddingInline: "2.5rem" }}
                          onClick={() => stepOneSubmit()}
                        >
                          Create your link
                        </Button>
                      </div>
                    ) : (
                      <div className="text-right mt-3 mb-4 ">
                        <Button
                          className=" mt-3"
                          color="primary"
                          size="lg"
                          onClick={() => stepOneSubmit()}
                        >
                          Next <ArrowRight size={17} className="ml-2" />
                        </Button>
                      </div>
                    )}
                  </Form>
                  <div>
                    <h5 style={{ alignItems: "center", textAlign: "center" }}>
                      Already have an account ?{" "}
                      <span>
                        <Text
                          className="primary-color"
                          onClick={() => {
                            localStorage.removeItem("temp_data");
                            if (queryPackage.coupon && queryPackage.plan) {
                              history.push(
                                `/auth/sign-in?plan=${queryPackage.plan}&amount=${queryPackage.amount}&couponCode=${queryPackage.coupon}`
                              );
                            } else if (queryPackage.plan) {
                              history.push(
                                `/auth/sign-in?plan=${queryPackage.plan}&amount=${queryPackage.amount}`
                              );
                            } else if (longUrl) {
                              history.push(
                                `/auth/sign-in?long_url=${longUrl}&link_name=${linkName}`
                              );
                            } else {
                              history.push(`/auth/sign-in`);
                            }
                          }}
                        >
                          Click Here
                        </Text>
                      </span>
                    </h5>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        )}
      </div>
    );
  } else if (step === 2) {
    return (
      <div>
        <div>
          <p className="h3 mb-2 page-headers-1 text-dark text-center">
            Get Started
          </p>
          <p className="lead mt-2 text-center">Tell us about you</p>
        </div>
        <Card className="mb-0">
          <CardBody>
            <Form>
              <FormGroup>
                <Label style={{ fontSize: "14px" }}>
                  Company Name<span className="text-danger">*</span>
                </Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="company_name"
                  id="company_name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Enter your company name"
                  style={errors?.company ? { border: "1px solid red" } : {}}
                />
                {errors?.company && (
                  <p style={{ color: "red" }} className="mb-0">
                    {errors?.company}
                  </p>
                )}
              </FormGroup>
              <FormGroup>
                <Label style={{ fontSize: "14px" }}>
                  Industry<span className="text-danger">*</span>
                </Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="industry"
                  id="industry"
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                  placeholder="Enter your industry"
                  style={errors?.industry ? { border: "1px solid red" } : {}}
                />
                {errors?.industry && (
                  <p style={{ color: "red" }} className="mb-0">
                    {errors?.industry}
                  </p>
                )}
              </FormGroup>
              <FormGroup>
                <Label style={{ fontSize: "14px" }}>
                  Your Role<span className="text-danger">*</span>
                </Label>
                <Input
                  bsSize="lg"
                  type="text"
                  name="role"
                  id="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  placeholder="Enter your role"
                  style={errors?.role ? { border: "1px solid red" } : {}}
                />
                {errors?.role && (
                  <p style={{ color: "red" }} className="mb-0">
                    {errors?.role}
                  </p>
                )}
              </FormGroup>
              <div className="d-flex justify-content-between mt-sm-5 align-items-center">
                <div className="mb-sm-0 mb-3">
                  <Button
                    className=""
                    color="primary"
                    size="lg"
                    onClick={() => setStep(1)}
                  >
                    <ArrowLeft
                      size={17}
                      className="mr-2"
                      style={{ marginBottom: "1.7px" }}
                    />
                    Back
                  </Button>
                </div>
                <div className="mb-sm-0 mb-3">
                  <Button
                    className=""
                    color="primary"
                    size="lg"
                    style={{ paddingInline: "2.5rem" }}
                    onClick={() => sendData()}
                  >
                    {longUrl ? "Create your link" : "Sign up"}
                  </Button>
                </div>
                {/* <div className="">
                  <Button
                    className=""
                    color="primary"
                    size="lg"
                    style={{ paddingInline: "2.5rem" }}
                    onClick={() => sendData()}
                  >
                    Skip for now
                  </Button>
                </div> */}
              </div>
            </Form>
          </CardBody>
        </Card>

        <FreeTrialPromoCode
          toggleFreeTrialModal={toggleFreeTrialModal}
          freeTrialModal={freeTrialModal}
          history={history}
          setStep={setStep}
          companyName={companyName}
          role={role}
          longUrl={longUrl}
          linkName={linkName}
          matchedPackage={matchedPackage}
          industry={industry}
        />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    purlPackageInRes: state.auth.purlPackageInRes,
    paymentRedirectionUrl: state.auth.paymentRedirectionUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetStartedAction: (data, history, setStep) =>
      dispatch(GetStartedAction(data, history, setStep)),
    emptyPurlPackage: () => dispatch(emptyPurlPackage()),
    validateUserPayment: () => dispatch(validateUserPayment()),
    setCouponCode: (couponCode) => dispatch(setCouponCode(couponCode)),
    redirectToPaymentPortal: (plan, coupon) =>
      dispatch(redirectToPaymentPortal(plan, coupon)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetStarted);

const Showeye = styled(Eye)`
  position: absolute;
  color: hsl(0, 0%, 80%);
  width: 25px;
  height: 30px;
  right: 30px;
  padding-top: 7px;
  z-index: 1;
`;
const Noeye = styled(EyeSlash)`
  position: absolute;
  color: hsl(0, 0%, 80%);
  width: 25px;
  height: 30px;
  right: 30px;
  padding-top: 7px;
  z-index: 1;
`;
const Text = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;
