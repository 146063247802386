import { BACKEND_ENDPOINT } from "../config";
import axios from "axios";

export const getShortDomain = () => {
  const reqUrl = `${BACKEND_ENDPOINT}/short-domain`;
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  axios
    .get(reqUrl, {
      headers: {
        authorization: `Bearer ${authorization[1].split(";")[0]}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch(() => {});
};
export const fetchImage = async (img, ext) => {
  ext ? (ext = "ico") : (ext = "svg");
  try {
    const response = await import(`../assets/img/brands/${img}.${ext}`);
    return response.default;
  } catch (err) {
    console.log("Error");
  }
};
export const fetchClientInfo = async (subdomain) => {
  const reqUrl = `${BACKEND_ENDPOINT}/client/get?client=${subdomain}`;
  let data = await axios.get(reqUrl);
  return data;
};
