import React, { useEffect } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import purlIconLarge from "../../../../assets/img/brands/purl logo.svg";
import { connect } from "react-redux";
import { formSubmitSuccess } from "../../../../redux/actions/subscriberActions";
import { isValidPublicDomain } from "../../../../config";

function CreationSuccess({ formSubmitSuccess }) {
  const newPageTitle = "purl - Create purls Success";
  document.title = newPageTitle;

  useEffect(() => {
    formSubmitSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Render starts here
  return (
    <div className="mt-7 mt-lg-0">
      <Card>
        <div className="m-2">
          <CardBody className="w-100 min-vh-50 d-flex flex-column justify-content-center align-items-center">
            <div className="mb-3">
              <img
                src={purlIconLarge}
                alt="Purl Logo"
                width={200}
                height={200}
              />
            </div>
            <div className="mb-4 mx-2 mx-md-5 text-center">
              <h3>
                We&apos;ve received your{" "}
                {isValidPublicDomain ? "trackable link" : "campaign"}, and
                it&apos;s currently in the processing queue. Congratulations!
              </h3>
            </div>
            <Button
              color="primary"
              href="/campaigns"
              className="one-rem-font-size my-3"
            >
              Back to View{" "}
              {isValidPublicDomain ? "Trackable Links" : "Campaigns"}
            </Button>
          </CardBody>
        </div>
      </Card>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    formSubmitSuccess: () => dispatch(formSubmitSuccess()),
  };
};

export default connect(null, mapDispatchToProps)(CreationSuccess);
