import React, { useState } from "react";
import {
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  UncontrolledTooltip,
  Row,
  Col,
  CustomInput,
} from "reactstrap";
import { Eye, EyeSlash } from "@styled-icons/bootstrap";
import styled from "styled-components";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { customStyles, errorStyles } from "../../../../components/CustomStyle";
import { connect } from "react-redux";
import DateTime from "react-datetime";
import "../CreatePurls/custom-datetime.css";
import moment from "moment";
import { InfoCircleFill } from "@styled-icons/bootstrap/InfoCircleFill";
import { jwtDecode } from "jwt-decode";
import { isValidPublicDomain } from "../../../../config";
const Noeye = styled(EyeSlash)`
  position: absolute;
  color: hsl(0, 0%, 80%);
  width: 25px;
  height: 30px;
  right: 50px;
  padding-top: 7px;
  z-index: 1;
`;
const Showeye = styled(Eye)`
  position: absolute;
  color: hsl(0, 0%, 80%);
  width: 25px;
  height: 30px;
  right: 50px;
  padding-top: 7px;
  z-index: 1;
`;
const CopyForm = ({
  campaign_name,
  setCampaignName,
  error,
  tagOptions,
  shortDomainOptions,
  shortDomain,
  tag,
  team,
  teamsOptions,
  handleTag,
  selectShort,
  setSelectShort,
  expiry,
  setExpiry,
  landingPage,
  handleLandingPageInput,
  utmSource,
  setUtmSource,
  utmCampaign,
  setUtmCampaign,
  utmContent,
  setUtmContent,
  utmMedium,
  setUtmMedium,
  setSelectdTeam,
  selectdTeam,
  setPassword,
  password,
  setChecked1,
  checked1,
}) => {
  React.useEffect(() => {
    if (shortDomain !== undefined) {
      setSelectShort(shortDomain);
    }

    if (team.value !== undefined && team.label !== undefined) {
      setSelectdTeam(team);
    }
  }, [shortDomain]);
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const handleCheckboxChange = () => {
    setChecked1((prevState) => !prevState);
  };
  const local_user = JSON.parse(localStorage.getItem("user"));
  const token = local_user?.authorization?.split("=")[1]?.split(";")[0];
  const decoded = token ? jwtDecode(token) : "";
  const handleDateChange = (value) => {
    setExpiry(moment(value).format());
  };
  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100">
      <CardBody className="w-100">
        <Form id="formElem">
          <FormGroup>
            <Label className="one-rem-font-size">
              {isValidPublicDomain ? "Trackable Link Name" : "Campaign Name"}
              <span style={{ color: "#d9534f" }}>*</span>
            </Label>
            <Input
              className="custom-input"
              bsSize="lg"
              type="text"
              name="title"
              id="title"
              value={campaign_name}
              placeholder={
                isValidPublicDomain
                  ? "Enter Trackable Link Name"
                  : "Enter Campaign Name"
              }
              onChange={(e) => setCampaignName(e.target.value)}
              style={error?.title ? { border: "1px solid red" } : {}}
              required
            />
            <div
              style={{
                color: "red",
                width: "100%",
              }}
            >
              <p>{error?.title}</p>
            </div>
          </FormGroup>
          <FormGroup>
            <Label className="one-rem-font-size">
              Landing Page<span style={{ color: "#d9534f" }}>*</span>
            </Label>
            <Input
              className="custom-input"
              bsSize="lg"
              type="text"
              name="landing_page"
              id="landing_page"
              value={landingPage}
              placeholder="Enter Landing Page"
              onChange={(e) => handleLandingPageInput(e.target.value)}
              required
              style={error?.landingPage ? { border: "1px solid red" } : {}}
            />
            <div
              style={{
                color: "red",
                width: "100%",
              }}
            >
              <p>{error?.landingPage}</p>
            </div>
          </FormGroup>
          <FormGroup>
            <Label className="one-rem-font-size">
              Select Short Domain<span style={{ color: "#d9534f" }}>*</span>
            </Label>
            <div style={{ width: "100%" }}>
              <Select
                className="react-select-container w-100"
                styles={error?.domainId ? errorStyles : customStyles}
                placeholder="Select Domain..."
                value={selectShort}
                options={shortDomainOptions}
                onChange={(selectedOption) => {
                  if (!selectedOption) {
                    setSelectShort([]);
                  } else {
                    setSelectShort(selectedOption);
                  }
                }}
                isClearable
              />
            </div>
            <div
              style={{
                color: "red",
                width: "100%",
              }}
            >
              <p>{error?.domainId}</p>
            </div>
          </FormGroup>
          <FormGroup>
            <Label className="one-rem-font-size d-flex align-items-center">
              <span>
                {isValidPublicDomain
                  ? "Trackable Link Expiry"
                  : "Campaign Expiry"}
                <span style={{ color: "#d9534f" }}>*</span>
              </span>
              <InfoCircleFill
                size={16}
                className="cursor-pointer primary-color ml-2"
                id="expiryInfo"
                style={{
                  border: "none",
                  outline: "none",
                }}
              />
              <UncontrolledTooltip placement="top" target="expiryInfo">
                {isValidPublicDomain
                  ? decoded?.auto_delete_purl_days
                    ? `The default Trackable Links expiry has been set to ${decoded?.auto_delete_purl_days} days.`
                    : "The default Trackable Links expiry has not been selected, if you are the Admin, complete the Onboarding journey to set the default Trackable Links expiry. Otherwise, Kindly contact your System admin."
                  : decoded?.auto_delete_purl_days
                  ? `The default Campaign expiry has been set to ${decoded?.auto_delete_purl_days} days.`
                  : "The default Campaign expiry has not been selected, if you are the Admin, complete the Onboarding journey to set the default campaign expiry. Otherwise, Kindly contact your System admin."}
              </UncontrolledTooltip>
            </Label>
            <DateTime
              inputProps={{
                placeholder: "DD/MM/YYYY HH:MM A",
                className: "custom-input form-control form-control-lg",
                // disabled: !checked,
                style: error?.expiry ? { border: "1px solid red" } : {},
                value: expiry
                  ? moment(expiry).format("DD/MM/YYYY hh:mm A")
                  : "",
              }}
              onChange={(e) => handleDateChange(e)}
              dateFormat="DD/MM/YYYY"
            />
            <div
              style={{
                color: "red",
                width: "100%",
              }}
            >
              <p>{error.expiry}</p>
            </div>
          </FormGroup>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <Label className="one-rem-font-size d-flex align-items-center">
                  <span>UTM Source</span>
                  <InfoCircleFill
                    size={16}
                    className="cursor-pointer primary-color ml-2"
                    id="sourceInfo"
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                  />
                  {isValidPublicDomain ? (
                    <UncontrolledTooltip placement="top" target="sourceInfo">
                      This is a required value when building tracking URLs to
                      attribute traffic to your trackable link and referred to
                      as utm_source in your URL. Please add where the trackable
                      link traffic will be directed to. Eg. If you are promoting
                      a website page, your source will be web.
                    </UncontrolledTooltip>
                  ) : (
                    <UncontrolledTooltip placement="top" target="sourceInfo">
                      This is a required value when building tracking URLs to
                      attribute traffic to your campaign and referred to as
                      utm_source in your URL. Please add where the campaign
                      traffic will be directed to. Eg. If you are promoting a
                      website page, your source will be web.
                    </UncontrolledTooltip>
                  )}
                </Label>
                <Input
                  className="custom-input"
                  bsSize="lg"
                  type="text"
                  name="utm_source"
                  id="utm_source"
                  value={utmSource.value}
                  placeholder="Enter UTM Source"
                  onChange={(e) =>
                    setUtmSource({
                      value: e.target.value,
                      isFromQuery: true,
                    })
                  }
                  required
                  style={error?.utmSource ? { border: "1px solid red" } : {}}
                  disabled={utmSource.value && !utmSource.isFromQuery}
                />
                <div
                  style={{
                    color: "red",
                    width: "100%",
                  }}
                >
                  <p>{error?.utmSource?.value}</p>
                </div>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Label className="one-rem-font-size text-nowrap">
                  <span>UTM Campaign</span>
                  <InfoCircleFill
                    size={16}
                    className="cursor-pointer primary-color ml-2"
                    id="campaignInfo"
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                  />
                  {isValidPublicDomain ? (
                    <UncontrolledTooltip placement="top" target="campaignInfo">
                      This is a required value when building tracking URLs to
                      attribute traffic to your trackable link and referred to
                      as utm_trackable link. Please add the name of your
                      trackable link.
                    </UncontrolledTooltip>
                  ) : (
                    <UncontrolledTooltip placement="top" target="campaignInfo">
                      This is a required value when building tracking URLs to
                      attribute traffic to your campaign and referred to as
                      utm_campaign. Please add the name of your campaign.
                    </UncontrolledTooltip>
                  )}
                </Label>
                <Input
                  className="custom-input"
                  bsSize="lg"
                  type="text"
                  name="utm_campaign"
                  id="utm_campaign"
                  value={utmCampaign.value}
                  placeholder="Enter UTM Campaign"
                  onChange={(e) =>
                    setUtmCampaign({
                      value: e.target.value,
                      isFromQuery: true,
                    })
                  }
                  required
                  disabled={utmCampaign?.value && !utmCampaign?.isFromQuery}
                />
                <div
                  style={{
                    color: "red",
                    width: "100%",
                  }}
                >
                  <p>{error?.utmCampaign?.value}</p>
                </div>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <FormGroup>
                <Label className="one-rem-font-size">
                  <span>UTM Content</span>
                  <InfoCircleFill
                    size={16}
                    className="cursor-pointer primary-color ml-2"
                    id="contentInfo"
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                  />
                  <UncontrolledTooltip placement="top" target="contentInfo">
                    Not a required element for tracking URLs, but great for
                    identifying how well specific elements in your content drive
                    clicks and conversions, (Eg. header-link, email-version).
                  </UncontrolledTooltip>
                </Label>
                <Input
                  className="custom-input"
                  bsSize="lg"
                  type="text"
                  name="utm_content"
                  id="utm_content"
                  value={utmContent.value}
                  placeholder="Enter UTM Content"
                  onChange={(e) =>
                    setUtmContent({
                      value: e.target.value,
                      isFromQuery: true,
                    })
                  }
                  required
                  disabled={utmContent?.value && !utmContent?.isFromQuery}
                />
                <div
                  style={{
                    color: "red",
                    width: "100%",
                  }}
                >
                  <p>{error?.utmContent?.value}</p>
                </div>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Label className="one-rem-font-size">
                  <span>UTM Medium</span>
                  <InfoCircleFill
                    size={16}
                    className="cursor-pointer primary-color ml-2"
                    id="mediumInfo"
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                  />
                  {isValidPublicDomain ? (
                    <UncontrolledTooltip placement="top" target="mediumInfo">
                      This is a required value when building tracking URLs to
                      attribute traffic to your trackable link and referred to
                      as utm_medium in your URL. Please add where how the
                      trackable link traffic is coming in (eg. paid-media or
                      owned-media).
                    </UncontrolledTooltip>
                  ) : (
                    <UncontrolledTooltip placement="top" target="mediumInfo">
                      This is a required value when building tracking URLs to
                      attribute traffic to your campaign and referred to as
                      utm_medium in your URL. Please add where how the campaign
                      traffic is coming in (eg. paid-media or owned-media).
                    </UncontrolledTooltip>
                  )}
                </Label>
                <Input
                  className="custom-input"
                  bsSize="lg"
                  type="text"
                  name="utm_medium"
                  id="utm_medium"
                  value={utmMedium.value}
                  placeholder="Enter UTM Medium"
                  onChange={(e) =>
                    setUtmMedium({
                      value: e.target.value,
                      isFromQuery: true,
                    })
                  }
                  required
                  disabled={utmMedium.value && !utmMedium.isFromQuery}
                />
                <div
                  style={{
                    color: "red",
                    width: "100%",
                  }}
                >
                  <p>{error?.utmMedium?.value}</p>
                </div>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Label className="one-rem-font-size">
              Select Top Tags or Create Tags
            </Label>
            <div style={{ width: "100%" }}>
              <CreatableSelect
                isMulti
                onChange={handleTag}
                options={tagOptions}
                value={tag}
                styles={error?.tag ? errorStyles : customStyles}
                placeholder="Select Top Tags or Create Tags..."
              />
            </div>
            <div
              style={{
                color: "red",
                width: "100%",
              }}
            >
              <p>{error?.tag}</p>
            </div>
          </FormGroup>

          {!isValidPublicDomain && (
            <FormGroup>
              <Label className="one-rem-font-size">Assign to Team</Label>
              <div style={{ width: "100%" }}>
                <Select
                  className="react-select-container w-100"
                  styles={customStyles}
                  value={selectdTeam}
                  options={teamsOptions}
                  placeholder="Select A Team..."
                  onChange={(selectedOption) => {
                    if (!selectedOption) {
                      setSelectdTeam([]);
                    } else {
                      setSelectdTeam(selectedOption);
                    }
                  }}
                  isClearable
                />
              </div>
            </FormGroup>
          )}

          <FormGroup>
            <Label className="one-rem-font-size">
              <CustomInput
                type="checkbox"
                className="custom-control-label::after"
                id="lockLink"
                checked={checked1}
                onChange={handleCheckboxChange}
                label={<span>Lock Link</span>}
              ></CustomInput>
            </Label>
            {checked1 && (
              <div className="d-flex">
                <Input
                  bsSize="lg"
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={error?.password ? { border: "1px solid red" } : {}}
                />
                {!passwordShown ? (
                  <Noeye
                    className="cursor-pointer"
                    onClick={togglePasswordVisiblity}
                  />
                ) : (
                  <Showeye
                    className="cursor-pointer"
                    onClick={togglePasswordVisiblity}
                  />
                )}
              </div>
            )}

            {checked1 && (
              <div
                style={{
                  color: "red",
                  width: "100%",
                }}
              >
                <p>{error?.password}</p>
              </div>
            )}
          </FormGroup>
        </Form>
      </CardBody>
    </div>
  );
};

const mapPropsToState = (state) => {
  return {
    fileUploadState: state.subscriber.fileUploadState,
  };
};

export default connect(mapPropsToState, null)(CopyForm);
