import * as types from "../constants";

const initialState = {
  reports: [],
  reportdata: [],
  reportsDate: [],
  loading: false,
  error: false,
  reportState: false,
  calender: [],
  calenderState: false,
  filter: [],
  fliterState: false,
  setSearch: "",
  setRefresh: "",
  refreshGraph: false,
  refreshGraphSuccess: "",
  topTenReports: [],
  refreshPage: false,
  linksData: [],
  graphData: [],
  miniLinksData: [],
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.FETCH_REPORTS:
      return {
        ...state,
        reports: actions.payload,
        reportState: true,
        loading: false,
      };
    case types.FETCH_REPORTS_DATE:
      return {
        ...state,
        reportsDate: actions.payload,
        loading: false,
        refreshGraph: true,
        refreshGraphSuccess: actions.payload,
        refreshPage: false,
      };
    case types.FETCH_REPORTS_DATA:
      return {
        ...state,
        reportdata: actions.payload,
        reportState: true,
        loading: false,
        refreshPage: false,
      };
    case types.FETCH_LINKS_DATA:
      return {
        ...state,
        linksData: actions.payload,
        loading: false,
        refreshPage: false,
      };
    case types.FETCH_GRAPH_DATA:
      return {
        ...state,
        graphData: actions.payload,
        loading: false,
        refreshPage: false,
      };
    case types.LOAD_REPORTS:
      return {
        ...state,
        loading: true,
      };
    case types.REPORTS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.REPORTS_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.FETCH_DATE:
      return {
        ...state,
        calender: actions.payload,
        calenderState: true,
        loading: false,
      };
    case types.FETCH_FILTERED_DATA:
      return {
        ...state,
        filter: actions.payload,
        filterState: true,
        loading: false,
      };
    case types.FETCH_TOPTEN_URLS:
      return {
        ...state,
        topTenReports: actions.payload,
        loading: false,
      };
    case types.FETCH_FILTERED_DATA_BUTTON:
      return {
        ...state,
        setSearch: actions.payload,
      };
    case types.FILTERED_DATA_REFRESH:
      return {
        ...state,
        setRefresh: actions.payload,
        filter: "",
      };
    case types.RESET_TOP_TEN:
      return {
        ...state,
        topTenReports: [],
      };
    case types.RESET_PAGE:
      return {
        ...state,
        refreshPage: true,
      };
    case types.MINI_LINKS_DATA:
      return {
        ...state,
        miniLinksData: actions.payload,
      };
    default:
      return state;
  }
}
