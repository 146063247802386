import React from "react";
import { Pie } from "react-chartjs-2";
import { connect } from "react-redux";

import { CardBody, CardHeader, CardTitle } from "reactstrap";

const PurlPerformanceChart = ({ theme, purlData }) => {
  const data = {
    labels: purlData.label,
    datasets: [
      {
        data: purlData.clicks,
        backgroundColor: [theme.primary, theme.warning],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    legend: {
      display: true,
      position: "bottom",
      onHover: (event) => {
        event.target.style.cursor = "pointer";
      },
      onLeave: (event) => {
        event.target.style.cursor = "default";
      },
    },

    tooltips: {
      mode: "dataset",
    },
  };

  return (
    <div className="flex-fill w-100 border rounded">
      <CardHeader className="pb-0 mb-0">
        <CardTitle tag="h5" className="text-center mb-0 font-weight-bold">
          Purl Performance Chart
        </CardTitle>
      </CardHeader>
      <CardBody className="d-flex mt-0">
        <div className="align-self-center w-100">
          <div className="chart chart-sm" style={{ minHeight: '140px' }}>
            <Pie data={data} options={options} />
          </div>
        </div>
      </CardBody>
    </div>
  );
};

export default connect((store) => ({
  theme: store.theme.currentTheme,
}))(PurlPerformanceChart);
