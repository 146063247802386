import * as types from "../constants";

const initialState = {
  user: [],
  singleuser: [],
  updateuser: {
    user_id: null,
    full_name: null,
    email: null,
    phone: null,
    role_id: null,
    status: null,
  },
  loading: false,
  teamLoading: false,
  error: false,
  edituser: false,
  edituserData: "",
  systemUserToken: "",
  userTokenState: false,
  tokenData: "",
  createModalToggle: false,
  teamUpadationSuccess: false,
  teamList: [],
  domainLoading: false,
  domains: [],
  domainsName: [],
  domainLoadError: false,
  addDomainLoading: false,
  deleteDomainSuccess: false,
  domainCreationSuccess: false,
  subDomainAdditionError: false,
  userProfileData: null,
  errorState: null,
  newTokenData: null,
  subscriptionCancelled: false,
  pricingTableData: null,
  promoCodeData: null,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.FETCH_USER:
      return {
        ...state,
        user: actions.payload,
        loading: false, // #TODO: update as per response here
      };
    case types.FETCH_SINGLE_USER:
      return {
        ...state,
        singleuser: actions.payload,
        loading: false, // #TODO: update as per response here
      };
    case types.LOAD_USER:
      return {
        ...state,
        loading: true,
      };
    case types.USER_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        edituser: true,
        edituserData: actions.payload,
      };
    case types.CREATE_SYSTEM_USER:
      return {
        ...state,
        systemUserToken: actions.payload.system_token,
        userTokenState: true,
      };
    case types.REGENERATE_TOKEN:
      return {
        ...state,
        systemUserToken: actions.payload.token,
        userTokenState: true,
      };
    case types.CREATE_SYSTEM_USER_SUCCESS:
      return {
        ...state,
        systemUserToken: "",
        userTokenState: false,
        tokenData: state.tokenData + "successful" + 1,
      };
    case types.EDIT_USER_SUCCESS:
      return {
        ...state,
        edituser: true,
        edituserData: actions.payload,
      };
    case types.MODAL_TOGGLE:
      return {
        ...state,
        createModalToggle: actions.payload,
      };

    case types.FETCH_TEAMS_LIST:
      return {
        ...state,
        teamList: actions.payload,
      };
    case types.TEAM_UPDATION_SUCCESS:
      return {
        ...state,
        teamUpadationSuccess: actions.payload,
      };
    case types.TEAM_LOAD:
      return {
        ...state,
        teamLoading: actions.payload,
      };
    case types.LOAD_DOMAIN:
      return {
        ...state,
        domainLoading: true,
      };
    case types.FETCH_DOMAINS:
      return {
        ...state,
        domains: actions.payload,
        domainLoading: false,
      };
    case types.FETCH_DOMAINS_NAME:
      return {
        ...state,
        domainsName: actions.payload,
        domainLoading: false,
      };
    case types.DOMAIN_ERROR:
      return {
        ...state,
        domainLoading: false,
        domainLoadError: true,
      };
    case types.SUBDOMAIN_ADDITION_ERROR:
      return {
        ...state,
        subDomainAdditionError: actions.payload,
      };
    case types.ADD_DOMAIN_LOADING:
      return {
        ...state,
        addDomainLoading: actions.payload,
      };
    case types.DELETE_DOMAIN_SUCCESS:
      return {
        ...state,
        deleteDomainSuccess: actions.payload,
      };
    case types.DOMAIN_CREATION_SUCCESS:
      return {
        ...state,
        domainCreationSuccess: actions.payload,
      };
    case types.FETCH_PROFILE_DATA:
      return {
        ...state,
        userProfileData: actions.payload,
      };
    case types.ERROR_STATE:
      return {
        ...state,
        errorState: actions.payload,
      };
    case types.NEW_TOKEN_DATA:
      return {
        ...state,
        newTokenData: actions.payload,
      };
    case types.SUBSCRIPTION_CANCELLED: {
      return {
        ...state,
        subscriptionCancelled: actions.payload,
      };
    }
    case types.FETCH_PRICING_TABLE_DATA:
      return {
        ...state,
        pricingTableData: actions.payload,
      };
    case types.PROMO_CODE_DATA:
      return {
        ...state,
        promoCodeData: actions.payload,
      };
    default:
      return state;
  }
}
