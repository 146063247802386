import * as types from "../constants";
const initialState = {
  list: {
    full_name: "",
    phone: "",
    email: "",
    role_title: "",
    query: [],
    query_description: "",
    files: [],
  },
  uploadState: false,
  error: { errorMessage: null, errorState: false },
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.NEW_HELP_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          full_name: actions.payload.formData.full_name,
          phone: actions.payload.formData.phone,
          email: actions.payload.formData.email,
          role_title: actions.payload.formData.role_title,
          query: actions.payload.formData.query,
          query_description: actions.payload.formData.query_description,
          files: actions.payload.formData.files,
        },
        uploadState: true,
      };
    case types.NEW_HELP_REQUEST_SUCCESS:
      return {
        ...state,
        uploadState: false,
        errorState: false,
        error: { ...state.error, errorMessage: null, errorState: false },
      };
    case types.SET_ERRORS:
      return {
        ...state,
        error: { ...state.error, errorState: true },
      };
    case types.SET_ERRORS_SUCCESS:
      return {
        ...state,
        error: {
          ...state.error,
          errorMessage: actions.payload,
          errorState: false,
        },
      };
    default:
      return state;
  }
}
