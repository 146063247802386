import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { GetStartedAction } from "../../redux/actions/authActions";
function FreeTrialPromoCode({
  toggleFreeTrialModal,
  freeTrialModal,
  history,
  setStep,
  GetStartedAction,
  companyName,
  role,
  longUrl,
  linkName,
  matchedPackage,
  industry,
}) {
  useEffect(() => {
    setPromoCode("");
    setPromoCodeError("");
  }, [toggleFreeTrialModal]);

  let tokenData = JSON.parse(localStorage.getItem("temp_data"));
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeError, setPromoCodeError] = useState("");
  const handlePromoCode = (value) => {
    setPromoCode(value);
    if (value) {
      setPromoCodeError("");
    }
  };

  const applyAndSignup = () => {
    if (!promoCode) {
      setPromoCodeError("Please enter the promo code.");
    } else {
      const data = {
        full_name: tokenData?.full_name,
        email: tokenData?.email,
        password: tokenData?.password,
        phone: tokenData?.country.concat(tokenData?.phone),
        industry,
        company: companyName,
        industry_role: role,
        long_url: longUrl,
        link_name: linkName,
        free_trial_promo: promoCode,
        purl_use_id: 1,
      };
      GetStartedAction(data, history, setStep);
      setPromoCodeError("");
      setPromoCode("");
      toggleFreeTrialModal();
      // localStorage.removeItem("temp_data");
    }
  };
  const skip = () => {
    const data = {
      full_name: tokenData?.full_name,
      email: tokenData?.email,
      password: tokenData?.password,
      phone: tokenData?.country.concat(tokenData?.phone),
      purl_use_id: 1,
      industry,
      company: companyName,
      industry_role: role,
      long_url: longUrl,
      link_name: linkName,
      payment_plan: matchedPackage?.plan,
      free_trial_promo: null,
    };
    toggleFreeTrialModal();
    setPromoCode("");
    GetStartedAction(data, history, setStep);
    // localStorage.removeItem("temp_data");
  };
  return (
    <Modal
      centered
      isOpen={freeTrialModal}
      toggle={toggleFreeTrialModal}
      style={{ maxWidth: "500px" }}
    >
      <ModalHeader toggle={toggleFreeTrialModal}>
        <div style={{ fontSize: 22 }}>Free Trial</div>
      </ModalHeader>
      <ModalBody>
        <div className="px-3">
          <Label className="one-rem-font-size mb-0">
            Do you have a free trial promo code?
          </Label>
          <p className="mb-0">
            If yes, enter here and click apply then proceed to checkout.
          </p>
          <p className="mb-3">
            If not, click proceed. Don't worry you'll still get full access!
          </p>
          <div className="w-100">
            <div className="">
              <div className="1 w-100">
                <Input
                  className="custom-input"
                  bsSize="lg"
                  type="text"
                  name="promo_code"
                  id="promo_code"
                  value={promoCode}
                  placeholder="Promo Code"
                  onChange={(e) => handlePromoCode(e.target.value)}
                  style={promoCodeError ? { border: "1px solid red" } : {}}
                />
                {promoCodeError && (
                  <div
                    style={{
                      color: "red",
                      width: "100%",
                    }}
                  >
                    <p className="m-0">{promoCodeError}</p>
                  </div>
                )}
              </div>
              <div
                className="d-flex mt-3 justify-content-center justify-content-md-between flex-wrap"
                style={{ gap: "10px" }}
              >
                <Button
                  className="bg-white text-primary border border-primary w-full"
                  size="lg"
                  color="primary"
                  onClick={() => applyAndSignup()}
                >
                  Apply Promo Code
                </Button>
                <Button size="lg" color="primary" onClick={skip}>
                  Proceed to checkoout
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetStartedAction: (data, history, setStep) =>
      dispatch(GetStartedAction(data, history, setStep)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FreeTrialPromoCode);
