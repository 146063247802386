import React, { useState, useEffect } from "react";
import Img from "../../assets/img/brands/pURL_Coloured_Logo_Landscape-removebg-preview (1) 2.svg";
import { fetchClientInfo } from "../../helper/helper";
import styled from "styled-components";

const ImgContainer = styled.img`
  object-fit: contain;
`;

const LinkExpiry = () => {
  let [image, setImage] = useState("");
  const [noImage, setNoImage] = useState(false);

  const url = window.location.href.split("/")[2].split(".")[0];
  useEffect(() => {
    const info = async () => {
      let im = await fetchClientInfo(url);
      if (im.data.logo_location) {
        setImage(im.data.logo_location);
      } else {
        setNoImage(true);
      }
    };
    info();
  }, [url]);

  return (
    <div className="d-flex flex-column flex-wrap justify-content-center align-content-center min-vh-100">
      <div className="text-center">
        <p className="h3 font-weight-normal">This link is expired or invalid.</p>
      </div>
      <div className="d-flex justify-content-center align-content-center mt-4">
        {image ? (
          <ImgContainer src={image} alt={Img} width="130" height="130" />
        ) : noImage ? (
          <ImgContainer src={Img} alt="Purl Logo" width="225" height="175" />
        ) : null}
      </div>
    </div>
  );
};

export default LinkExpiry;
