import * as types from "../constants";
const initialState = {
  list: {
    tags: [],
    file: [],
    status: "",
    subscriber_list_id: null,
    campaign_name: "",
    short_domain_id: null,
  },
  fileState: {
    uploadState: false,
    editState: false,
  },
  shortDomain: [
    {
      short_domain_id: null,
      short_domain: "",
      counter: "",
      salt: "",
    },
  ],
  error: { errorMessage: null, errorState: false },
  teams: [],
  subscriberLoadState: "",
  shortDomainLoadState: false,
  teamsLoadState: false,
  shortDomainState: false,
  teamState: false,
  loading: false,
  tags: [],
  tag: [],
  subscriberlist: [],
  dataSucccess: false,
  deleteState: false,
  reportList: [],
  formSubmissionStatus: null,
  fileUploadPath: [],
  fileUploadStart: false,
  fileUploadState: false,
  fileUploading: false,
  uploadErrorState: false,
  singleReportData: {},
  singleReportChartData: {},
  singleReportTableData: [],
  tableDataLoading: true,
  refresh: false,
  campaignDropdownData: [],
  retargetCampaignData: [],
  retargetCampaignLoading: false,
  retargetCampaignParentId: "",
  copyCampaignId: [],
  draftStatus: null,
  saveCampaignStatus: false,
  publicTableData: [],
  addNewShortUrlStatus: null,
  shortUrlModalOpening: false,
  shortUrlModalOpenState: false,
  shorturl: "",
  longurl: "",
  linkInBio: "",
  addNewLinkInBioStatus: null,
  linkInBioModalOpenState: false,
  tabState: "2",
  packageValidityData: null,
  userPackageExpiry: null,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.ADD_NEW_SUBSCRIBER_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          campaign_name: actions.payload.formData.title,
          tags: actions.payload.formData.tag,
          file: actions.payload.formData.file,
          short_domain_id: actions.payload.short_domain_id,
        },
        uploadState: true,
        subscriberLoadState: actions.payload,
      };
    case types.ADD_NEW_SUBSCRIBER_LIST_SUCCESS:
      return {
        ...state,
        uploadState: false,
        errorState: false,
        error: { ...state.error, errorMessage: null, errorState: false },
        // subscriberLoadState: actions.payload,
        formSubmissionStatus: actions.payload,
      };
    case types.SHORT_URL:
      return {
        ...state,
        shorturl: actions.payload,
      };
    case types.LONG_URL:
      return {
        ...state,
        longurl: actions.payload,
      };
    case types.ADD_NEW_SHORT_URL_SUCCESS:
      return {
        ...state,
        addNewShortUrlStatus: actions.payload,
      };

    case types.OPEN_SHORT_URL_MODAL:
      return {
        ...state,
        shortUrlModalOpenState: actions.payload,
      };
    case types.LINK_IN_BIO:
      return {
        ...state,
        linkInBio: actions.payload,
      };
    case types.ADD_NEW_LINK_IN_BIO_SUCCESS:
      return {
        ...state,
        addNewLinkInBioStatus: actions.payload,
      };
    case types.OPEN_LINK_IN_BIO_MODAL:
      return {
        ...state,
        linkInBioModalOpenState: actions.payload,
      };
    case types.ADD_NEW_DRAFT:
      return {
        ...state,
        draftStatus: actions.payload,
      };
    case types.UPDATE_A_DRAFT:
      return {
        ...state,
        draftStatus: actions.payload,
      };
    case types.EDIT_SUBSCRIBER_LIST:
      return {
        ...state,
        subscriberLoadState: actions.payload,
      };
    case types.EDIT_SUBSCRIBER_LIST_SUCCESS:
      return {
        ...state,
        formSubmissionStatus: actions.payload,
      };
    case types.GET_SUBSCRIBER_LIST:
      return {
        ...state,
        list: actions.payload,
        loading: false,
      };
    case types.LOAD:
      return { ...state, loading: true };
    case types.GET_SHORT_DOMAIN:
      return {
        ...state,
        shortDomainLoadState: false,
        shortDomainState: true,
        shortDomain: actions.payload,
      };
    case types.GET_TEAMS:
      return {
        ...state,
        teamsLoadState: false,
        teamState: true,
        teams: actions.payload,
      };
    case types.GET_ALL_TAGS:
      return { ...state, tag: actions.payload, loading: false };
    case types.SET_ERRORS:
      return {
        ...state,
        error: { ...state.error, errorState: true },
        fileUploading: false,
      };
    case types.SET_ERRORS_SUCCESS:
      return {
        ...state,
        error: {
          ...state.error,
          errorMessage: actions.payload,
          errorState: false,
          fileUploading: false,
        },
      };
    case types.FETCH_SUBSCRIBER_LIST:
      return {
        ...state,
        subscriberlist: actions.payload,
        dataSucccess: true,
        loading: false, // #TODO: update as per response here
      };
    case types.REPORT_SUBSCRIBER_LIST:
      return {
        ...state,
        reportList: actions.payload,
        dataSucccess: true,
        loading: false, // #TODO: update as per response here
      };
    case types.REPORT_SUBSCRIBER_LIST_SUCCESS:
      return { ...state, dataSucccess: false };
    case types.LOAD_SUBSCRIBER_LIST:
      //
      return {
        ...state,
        loading: true,
      };
    case types.SUBSCRIBER_LIST_ERROR:
      //
      return {
        ...state,
        loading: false,
        error: { ...state.error, errorState: true },
      };
    case types.DELETE_SUBSCRIBER_LIST:
      return {
        ...state,
        subscriberLoadState: actions.payload,
        deleteState: true,
        // loading: false,
      };
    case types.DELETE_SUBSCRIBER_LIST_SUCCESS:
      return {
        ...state,
        deleteState: false,
        // loading: false,
      };
    case types.FILE_UPLOAD_START:
      return {
        ...state,
        fileUploadStart: true,
      };

    case types.GET_SIGNED_URLS:
      return {
        ...state,
        fileUploadPath: [...state.fileUploadPath, ...actions.payload],
      };
    case types.EMPTY_SIGNED_URLS:
      return {
        ...state,
        fileUploadPath: actions.payload,
      };
    case types.GET_SIGNED_URLS_SUCCESS:
      return {
        ...state,
        fileUploadStart: false,
        // fileUploading: true,
        fileUploadState: true,
      };
    case types.UPLOAD_FILES:
      return {
        ...state,
        fileUploadState: true,
      };
    case types.UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        fileUploadState: false,
        fileUploading: false,
      };
    case types.UPLOAD_ERROR:
      return {
        ...state,
        uploadErrorState: true,
        fileUploadStart: false,
      };
    case types.UPLOAD_ERROR_SUCCESS:
      return {
        ...state,
        uploadErrorState: false,
        fileUploadStart: false,
      };
    case types.FORM_SUBMIT_SUCCESS:
      return {
        ...state,
        formSubmissionStatus: null,
      };
    case types.FETCH_SINGLE_CAMPAIGN_DATA:
      return {
        ...state,
        singleReportData: actions.payload,
      };
    case types.SINGLE_REPORT_CHART_DATA:
      return {
        ...state,
        singleReportChartData: actions.payload,
      };
    case types.FETCH_TABLE_DATA_LOADING:
      return {
        ...state,
        tableDataLoading: false,
      };
    case types.FETCH_TABLE_DATA:
      return {
        ...state,
        singleReportTableData: actions.payload,
      };
    case types.SINGLE_CAMPAIGN_REFRESH_DATA:
      return { ...state, refresh: true };
    case types.SINGLE_CAMPAIGN_REFRESH_STOP:
      return { ...state, refresh: false };
    case types.GET_CAMPAIGNS_DROPDOWN:
      return {
        ...state,
        campaignDropdownData: actions.payload,
      };
    case types.RETARGET_CAMPAIGN:
      return {
        ...state,
        retargetCampaignData: actions.payload,
      };
    case types.RETARGET_CAMPAIGN_LOADER:
      return {
        ...state,
        retargetCampaignLoading: true,
      };
    case types.RETARGET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        retargetCampaignLoading: false,
      };
    case types.RETARGET_CAMPAIGN_PARENT_ID:
      return {
        ...state,
        retargetCampaignParentId: actions.payload,
      };
    case types.COPY_CAMPAIGN_ID:
      return {
        ...state,
        copyCampaignId: actions.payload,
      };
    case types.SAVE_CAMPAIGN_STATUS:
      return {
        ...state,
        saveCampaignStatus: actions.payload,
      };
    case types.GET_TABLE_DATA:
      return { ...state, publicTableData: actions.payload };
    case types.TABSTATES:
      return { ...state, tabState: "1" };
    case types.CHECK_PACKAGE_VALIDITY:
      return { ...state, packageValidityData: actions.payload };
    case types.USER_PACKAGE_EXPIRY:
      return { ...state, userPackageExpiry: actions.payload };
    default:
      return state;
  }
}
