import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  UncontrolledAlert,
} from "reactstrap";
import { BACKEND_ENDPOINT } from "../../../../config";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import EditForm from "./EditForm";
import CryptoJS from "crypto-js";
import FileUpload from "../CreatePurls/FileUpload";
import {
  editList,
  getAllTag,
  fetchSubscriberLists,
} from "../../../../redux/actions/subscriberActions";
import moment from "moment";
import { connect } from "react-redux";
import { editListValidation } from "../../../validation/validation";
import TagManager from "react-gtm-module";
import { Bell } from "react-feather";
import { isValidPublicDomain } from "../../../../config";
function EditPurls({
  editList,
  tagData,
  getAllTag,
  formSubmissionStatus,
  fetchSubscriberLists,
}) {
  const history = useHistory();

  const newPageTitle = "purl - Edit purls";
  document.title = newPageTitle;

  const user = JSON.parse(localStorage.getItem("user"));
  const token = user && user.authorization.split("=")[1].split(";")[0];
  let userType = token && JSON.parse(atob(token.split(".")[1]));
  const campaignLimitLeft =
    localStorage.getItem("campaignLimitLeft") === "true";
  const plan = localStorage?.getItem("userPlan");
  const { id } = useParams();

  if (isNaN(Number(id))) {
    history.push("/auth/404");
  }
  const key = process.env.REACT_APP_PASSWORD_DECRYPTION_KEY;
  //States are defined here

  const [step, setStep] = useState(0);
  const [campaignData, setcampaignData] = useState("");
  const [campaign_name, setcampaign_name] = useState("");
  const [landingPage, setLandingPage] = useState("");
  const [landingPageFromBacked, setLandingPageFromBackend] = useState("");
  const [checked, setChecked] = useState("");
  const [expiry, setExpiry] = useState("");
  const [utmSource, setUtmSource] = useState({
    value: "",
    isFromQuery: true,
  });
  const [utmCampaign, setUtmCampaign] = useState({
    value: "",
    isFromQuery: true,
  });
  const [utmContent, setUtmContent] = useState({
    value: "",
    isFromQuery: true,
  });
  const [utmMedium, setUtmMedium] = useState({
    value: "",
    isFromQuery: true,
  });
  const [team, setTeam] = useState([]);
  const [shortDomain, setShortDomain] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [error, setError] = useState({ tag: "" });
  const [file, setfile] = useState([]);
  let [tag, setTag] = useState([]);
  let [tagOptions, setTagOptions] = useState([{ value: "", label: "" }]);
  const [fileStatus, setFileStatus] = useState(false);
  const [password, setPassword] = useState(null);
  const [decryptedPassword, setDecryptedPassword] = useState(null);
  useEffect(() => {
    getAllTag();

    const reqUrl = `${BACKEND_ENDPOINT}/campaign/${id}`;
    axios
      .get(reqUrl, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setcampaignData(res.data);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let url = landingPageFromBacked;
    const realUrl = landingPageFromBacked;
    if (url) {
      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = `https://${url}`;
      }
      const urlObject = new URL(url);
      const baseUrl = `${urlObject.origin}${urlObject.pathname}`;
      const decodedParams = Array.from(urlObject.searchParams.entries())
        .map(([key, value]) => `${key}=${decodeURIComponent(value)}`)
        .join("&");
      let finalUrl = baseUrl;
      if (decodedParams) {
        finalUrl += `?${decodedParams}`;
      }

      if (!realUrl.startsWith("http://") && !realUrl.startsWith("https://")) {
        if (finalUrl.startsWith("https://")) {
          finalUrl = finalUrl.slice(8);
        } else if (finalUrl.startsWith("http://")) {
          finalUrl = finalUrl.slice(7);
        }
        if (finalUrl.endsWith("/")) {
          finalUrl = finalUrl.slice(0, -1);
        }
      }
      setLandingPage(finalUrl);
    }
  }, [landingPageFromBacked]);
  useEffect(() => {
    const setUtms = () => {
      let url = landingPageFromBacked;
      try {
        const parsedUrl = new URL(url);
        const queryParams = parsedUrl.searchParams;
        const extractedParams = {};
        const utmPattern = /^utm[\s_-]*(source|campaign|content|medium)$/i;

        queryParams.forEach((value, key) => {
          if (utmPattern.test(key)) {
            const normalizedKey = key.toLowerCase().replace(/[\s_-]/g, "");
            const values = value.split(",");
            values.forEach((val, index) => {
              const utmKey =
                values.length > 1
                  ? `${normalizedKey}_${index + 1}`
                  : normalizedKey;
              extractedParams[utmKey] = val.trim();
            });
          }
        });

        const setUTM = (paramKey, stateSetter, fallbackValue) => {
          const normalizedParamKey = paramKey
            .toLowerCase()
            .replace(/[\s_-]/g, "");
          const matchedKey = Object.keys(extractedParams).find((key) =>
            key.startsWith(normalizedParamKey)
          );
          if (matchedKey) {
            stateSetter({
              value: extractedParams[matchedKey],
              isFromQuery: false,
            });
          } else {
            stateSetter({
              value: fallbackValue,
              isFromQuery: true,
            });
          }
        };

        setUTM("utm_source", setUtmSource, campaignData?.utm_source || "-");
        setUTM("utm_medium", setUtmMedium, campaignData?.utm_medium || "-");
        setUTM(
          "utm_campaign",
          setUtmCampaign,
          campaignData?.utm_campaign || "-"
        );
        setUTM("utm_content", setUtmContent, campaignData?.utm_content || "-");
      } catch (err) {
        setUtmSource({
          value: campaignData?.utm_source || "-",
          isFromQuery: true,
        });
        setUtmMedium({
          value: campaignData?.utm_medium || "-",
          isFromQuery: true,
        });
        setUtmContent({
          value: campaignData?.utm_content || "-",
          isFromQuery: true,
        });
        setUtmCampaign({
          value: campaignData?.utm_campaign || "-",
          isFromQuery: true,
        });
      }
    };

    setUtms();
  }, [landingPageFromBacked]);
  useEffect(() => {
    setcampaign_name(campaignData?.campaign_name);

    setLandingPageFromBackend(campaignData?.landing_page);

    setShortDomain({
      value: campaignData?.shortDomain?.short_domain_id,
      label: campaignData?.shortDomain?.short_domain,
    });
    setTeam({
      value: campaignData?.Team?.team_id || "-",
      label: campaignData?.Team?.team_name || "-",
    });

    setChecked(campaignData?.shorturl_expiry_time ? true : false);

    setExpiry(
      campaignData?.shorturl_expiry_time
        ? moment(campaignData?.shorturl_expiry_time).format(
            "DD/MM/YYYY hh:mm A"
          )
        : ""
    );

    setTag(
      campaignData?.subTagMappings?.map((el) => ({
        value: el.tag.tag_name,
        label: el.tag.tag_name,
      }))
    );

    setfile(campaignData?.files?.map((i) => i.file_name));
    setPassword(campaignData?.password);
  }, [campaignData]);

  useEffect(() => {
    setTagOptions(
      tagData?.map((t) => ({
        value: t.tag_name,
        label: t.tag_name,
      }))
    );
  }, [tagData]);

  useEffect(() => {
    if (password && key) {
      decryptDataWithAES(password, key);
    }
  }, [password]);

  const handleTag = (e) => {
    setTag(e);
  };

  const handleSubmitFirstStep = () => {
    tagArray();
    const finalUploadData = {
      campaign_id: id,
      campaign_name: campaign_name,
      tag: tag,
      file: fileData,
      domainId: shortDomain,
      expiry_state: checked,
      expiry: expiry,
      landing_page: landingPage,
      utm_source: utmSource?.value,
      utm_medium: utmMedium?.value,
      utm_content: utmContent?.value,
      utm_campaign: utmCampaign?.value,
      step: step,
      fileStatus: fileStatus,
    };
    const { formIsValid, error } = editListValidation(finalUploadData);
    setError(error);
    if (formIsValid) {
      setStep((step) => step + 1);
    }
  };

  const tagArray = () => {
    if (tag !== null) {
      tag = tag?.filter(function (e) {
        return e !== " ";
      });
    } else {
      tag = [];
    }
  };

  React.useEffect(() => {
    if (formSubmissionStatus && formSubmissionStatus === 200) {
      history.push("/campaigns");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmissionStatus]);

  const handleSubmit = () => {
    tagArray();
    const finalUploadData = {
      campaign_id: id,
      campaign_name: campaign_name,
      tag: tag,
      file: fileData,
      domainId: shortDomain,
      expiry_state: checked,
      expiry: expiry,
      landing_page: landingPage,
      utm_source: utmSource?.value,
      utm_medium: utmMedium?.value,
      utm_content: utmContent?.value,
      utm_campaign: utmCampaign?.value,
      fileStatus: fileStatus,
    };

    const { formIsValid, error } = editListValidation(finalUploadData);
    setError(error);
    if (formIsValid) {
      // ---------GTM Start----------
      const tagManagerArgs = {
        gtmId: "GTM-PSTCZS3",
        dataLayer: {
          event: "formSubmission",
          formName: "Add new subscriber list",
          userId: userType && userType.id,
        },
      };

      TagManager.initialize(tagManagerArgs);
      // --------- GTM End-------------

      editList(finalUploadData);
    }
  };
  function decryptDataWithAES(encryptedDataWithIV, keyHex) {
    const ivHex = encryptedDataWithIV.slice(0, 32);
    const encryptedDataHex = encryptedDataWithIV.slice(32);

    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const key = CryptoJS.enc.Hex.parse(keyHex);
    const encryptedData = CryptoJS.enc.Hex.parse(encryptedDataHex);

    const decrypted = CryptoJS.AES.decrypt({ ciphertext: encryptedData }, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const decryptedPassword = decrypted.toString(CryptoJS.enc.Utf8);
    setDecryptedPassword(decryptedPassword);
  }

  const stepTitles = ["Step 1: Campaign Details", "Step 2: Upload Files"];

  return (
    <div className="mt-7 mt-lg-0">
      {campaignLimitLeft === false && plan !== "shellpackage" && (
        <UncontrolledAlert
          color="primary"
          className="alert-outline-coloured w-100 mb-3"
        >
          <div className="alert-icon d-flex align-items-center">
            <Bell size={22} />
          </div>
          <div className="alert-message alert-message-components">
            <p className="mb-0 ">
              You've reached the maximum number of trackable links you can
              create, So you cannot edit this purl.
              <a
                className="ml-lg-1 ml-0"
                href="https://www.p-url.com/pricing"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-nowrap d-none d-sm-inline-block">
                  Know more about pricing
                </span>
                <span className="text-nowrap d-inline-block d-sm-none">
                  Take action
                </span>
              </a>
            </p>
          </div>
        </UncontrolledAlert>
      )}
      <Row className="mb-2 mb-xl-4">
        <Col xs="auto">
          <h1 className="mb-3 pl-2 pl-lg-0 text-dark">Edit purls</h1>
        </Col>
      </Row>

      <Card>
        <div className="m-2">
          <Row className="d-flex justify-content-center flex-column flex-lg-row">
            <h3 className="pl-4 ml-2 mb-1 mt-2 one-rem-font-size d-lg-none">
              {stepTitles[step]}
            </h3>
            <Col sm={12} md className={step == 0 ? "" : "d-none d-lg-block"}>
              <EditForm
                campaign_name={campaign_name}
                landing_page={landingPage}
                utm_source={utmSource}
                utmMedium={utmMedium}
                utmContent={utmContent}
                utmCampaign={utmCampaign}
                short_domain={shortDomain}
                checked={checked}
                expiry={expiry}
                tagOptions={tagOptions}
                team={team}
                handleTag={handleTag}
                tag={tag}
                error={error}
                password={password}
                decryptedPassword={decryptedPassword}
              />
            </Col>
            <Col sm={12} md className={step == 1 ? " " : "d-none d-lg-block"}>
              <FileUpload
                setFiles={setFileData}
                setError={setError}
                error={error}
                file={file}
                isEdit={true}
                setFileStatus={setFileStatus}
              />
            </Col>
            <Col sm={12}>
              <CardBody className="w-100 d-flex justify-content-end p-1">
                {step == 1 ? (
                  <div className="d-flex justify-content-between w-100">
                    <Button
                      color="primary"
                      href="#top"
                      onClick={() => {
                        setStep((step) => step - 1);
                      }}
                      className="d-lg-none"
                    >
                      <span>&#8592;</span> Back
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        handleSubmit();
                      }}
                      className="one-rem-font-size"
                    >
                      {isValidPublicDomain
                        ? "Update Trackable Link"
                        : "Update Campaign"}
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button
                      color="primary"
                      className="d-lg-none"
                      href="#top"
                      onClick={() => {
                        handleSubmitFirstStep();
                      }}
                    >
                      Next <span>&#8594;</span>
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        fetchSubscriberLists();
                        handleSubmit();
                      }}
                      disabled={!campaignLimitLeft}
                      className="one-rem-font-size d-none d-lg-block"
                    >
                      {isValidPublicDomain
                        ? "Update Trackable Link"
                        : "Update Campaign"}
                    </Button>
                  </div>
                )}
              </CardBody>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
}

const mapPropsToState = (state) => {
  return {
    list: state.subscriber.list,
    loadState: state.subscriber.loading,
    tagData: state.subscriber.tag,
    formSubmissionStatus: state.subscriber.formSubmissionStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editList: (data) => dispatch(editList(data)),
    getAllTag: () => dispatch(getAllTag()),
    fetchSubscriberLists: () => dispatch(fetchSubscriberLists()),
  };
};
export default connect(mapPropsToState, mapDispatchToProps)(EditPurls);
