import React from "react";
import { connect } from "react-redux";
import { Card, Button } from "reactstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import QRCode from "qrcode.react";

import Toastr from "../misc/Toaster";

const clientName = window.location.href.split("/")[2].split(".")[0];

const AuthenticatorSetup = ({ secretKey, firstTimeMfa }) => {
  const newPageTitle = "purl - 2FA setup";
  document.title = newPageTitle;

  const user = JSON.parse(localStorage.getItem("user"));

  const clientNameTitle =
    clientName?.charAt(0).toUpperCase() + clientName?.slice(1);

  const issuer = `PURL(${clientNameTitle})`;
  const accountName = user?.email;

  const provisioningURI = `otpauth://totp/${issuer}:${accountName}?secret=${secretKey}`;

  const history = useHistory();
  if (firstTimeMfa) {
    return (
      <div>
        <div className="text-center">
          <div className="text-center pb-0">
            <img
              alt={clientName}
              src={`https://storage.googleapis.com/purl-assets/client-logos/${clientName}.png`}
              width="150"
              height="90"
              style={{
                objectFit: "contain",
                maxWidth: "100%",
              }}
            />
          </div>
          <h3 className="my-3">Two Factor Authentication</h3>
        </div>
        <Card>
          <div className="px-4 pt-5 pb-4 d-flex flex-column">
            <div className="px-2">
              <p
                className="h5 text-secondary mb-0 border-bottom pb-3 text-center"
                style={{ fontSize: "1rem" }}
              >
                Please install an authenticator app on your Android/iOS device,
                such as Google Authenticator, Microsoft Authenticator, or Authy
              </p>
            </div>
            <div className="d-sm-flex my-4 position-relative text-center text-sm-left">
              <div
                className="d-flex flex-column p-2 mb-3 mb-sm-0 border rounded mx-0 mr-sm-0"
                style={{ flexBasis: "40%" }}
              >
                <QRCode
                  value={provisioningURI}
                  className="align-self-center "
                />
                <p className="px-2 px-sm-0 py-2 pb-sm-0 h5 text-secondary mb-0 text-center">
                  Use the authenticator app to scan the QR code.
                </p>
              </div>
              <div className="d-flex flex-column ">
                <div
                  className="px-3 px-sm-0 ml-sm-3 mt-3 mt-sm-0 d-flex  flex-column align-items-center"
                  style={{ flexBasis: "140%" }}
                >
                  <p
                    style={{ backgroundColor: "#f0f0f0", letterSpacing: "1px" }}
                    className="h3 p-2 mb-0 text-break text-center "
                  >
                    {secretKey}
                  </p>
                  <p className="px-2 py-1 py-sm-2 m-0 h5 text-secondary">
                    If you have any problem scanning the QR code, enter the code
                    manually into the app.
                  </p>
                  <div
                    className="d-flex justify-content-start w-100 px-2 py-4 py-sm-2 text-secondary my-2 border "
                    style={{ fontSize: "0.825rem" }}
                  >
                    <span>
                      <strong>NOTE: </strong>
                      While setting up 2FA with manually entered secret key,
                      choose "Time based" OTP (TOTP) for enhanced security.
                    </span>
                  </div>
                </div>
                <Button
                  color="primary align-self-end d-none d-sm-block mx-2"
                  onClick={() => history.push("/auth/otp")}
                >
                  Continue
                </Button>
              </div>
            </div>
            <div className="d-flex justify-content-between justify-content-sm-center align-items-center px-2">
              <Link
                to="/dashboard"
                className="mb-0 cursor-pointer"
                onClick={() => {
                  Toastr("Logged in Successfully.", "success");
                }}
              >
                skip for now
              </Link>
              <Button
                color="primary align-self-end d-sm-none d-block"
                onClick={() => history.push("/auth/otp")}
              >
                Next
              </Button>
            </div>
          </div>
        </Card>
      </div>
    );
  } else {
    return <Redirect to="/auth/otp" />;
  }
};

const mapStateToProps = (state) => {
  return {
    secretKey: state.auth.secretKey,
    firstTimeMfa: state.auth.firstTimeMfa,
  };
};

export default connect(mapStateToProps)(AuthenticatorSetup);
