import React, { useEffect } from "react";
import { Card, Button } from "reactstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import Toastr from "../misc/Toaster";
import { mfa } from "../../redux/actions/authActions";
import TwoFa from "../../assets/img/brands/2fa.svg";

const TwoFactorAuthentication = ({ mfaCall, secretKeyState }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const clientName = window.location.href.split("/")[2].split(".")[0];
  const newPageTitle = "purl - Two Factor Authentication";
  document.title = newPageTitle;

  const history = useHistory();

  useEffect(() => {
    if (secretKeyState) {
      history.push("/auth/2fa-configuration");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secretKeyState]);

  const mfa_enabled = user?.mfa_enabled;
  const mfa_type = user?.mfa_type;
  if (user) {
    if (mfa_enabled === false && mfa_type === "self") {
      return (
        <div>
          <div className="text-center">
            <div className="text-center pb-0">
              <img
                alt={clientName}
                src={`https://storage.googleapis.com/purl-assets/client-logos/${clientName}.png`}
                width="150"
                height="90"
                style={{
                  objectFit: "contain",
                  maxWidth: "100%",
                }}
              />
            </div>
            <h3 className="my-3">Two Factor Authentication</h3>
          </div>
          <Card>
            <div className="d-flex flex-column align-items-center py-4 px-4">
              <div className="d-flex align-items-center justify-content-center mb-3">
                <img src={TwoFa} alt="2FA" className="two-factor-img" />
              </div>
              <span className="text-center lead mb-3">
                Protect your account with an extra layer of security through Two
                Factor Authentication.
              </span>
              <span className="text-center lead mb-3">
                Would you like to enable it?
              </span>
              <Button
                color="primary"
                className="mb-3 px-5"
                size="lg"
                onClick={() => {
                  mfaCall();
                }}
              >
                Yes, I'm In!
              </Button>
              <Link
                to="/dashboard"
                className="mb-0 cursor-pointer hover:text-decoration-underline "
                onClick={() => {
                  Toastr("Logged in Successfully.", "success");
                }}
              >
                skip for now
              </Link>
            </div>
          </Card>
        </div>
      );
    } else if (mfa_type === "disabled") {
      return <Redirect to="/dashboard" />;
    } else {
      return <Redirect to="/dashboard" />;
    }
  } else {
    return <Redirect to="/auth/otp" />;
  }
};

const mapStateToProps = (state) => {
  return {
    secretKeyState: state.auth.secretKeyState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mfaCall: () => dispatch(mfa()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoFactorAuthentication);
