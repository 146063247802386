import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { publicLogin } from "./../../redux/actions/authActions";
import styled from "styled-components";
import { Eye, EyeSlash } from "@styled-icons/bootstrap";
import { purlPackages } from "../../config";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { LoginFormValidation } from "../validation/validation";
import Toastr from "../misc/Toaster";

const Text = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;

const Showeye = styled(Eye)`
  position: absolute;
  color: hsl(0, 0%, 80%);
  width: 25px;
  height: 30px;
  right: 50px;
  padding-top: 7px;
  z-index: 1;
`;
const Noeye = styled(EyeSlash)`
  position: absolute;
  color: hsl(0, 0%, 80%);
  width: 25px;
  height: 30px;
  right: 50px;
  padding-top: 7px;
  z-index: 1;
`;

const PublicSignIn = ({ publicLogin, stateErrors }) => {
  const [matchedPackage, setMatchedPackage] = useState(null);
  const [queryPackage, setQueryPackage] = useState({
    plan: null,
    amount: null,
    coupon: null,
  });
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [validError, setValidError] = useState({
    email: "",
    password: "",
  });

  const [longUrl, setLongUrl] = useState("");
  const [linkName, setLinkName] = useState("");

  const [passwordShown, setPasswordShown] = useState(false);

  useEffect(() => {
    if (window.location.search) {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.size > 0) {
        const queryLinkName = queryParams.get("link_name");
        const queryLongUrl = queryParams.get("long_url");
        if (queryLinkName && queryLongUrl) {
          let longURL = window.location.search.split("&link_name");
          let long_url = longURL[0].split("?long_url=")[1];
          let fetched_link_name =
            window.location.search.split("&link_name=")[1];
          let linkName = fetched_link_name.replace(/%20/g, " ");
          setLongUrl(long_url);
          setLinkName(linkName);
          setUser((prevUser) => ({
            ...prevUser,
            long_url: long_url,
            link_name: linkName,
          }));
        }
      }
    }
  }, []);

  useEffect(() => {
    if (matchedPackage?.plan) {
      setUser((prevUser) => ({
        ...prevUser,
        payment_plan: matchedPackage.plan,
      }));
    }
  }, [matchedPackage]);

  useEffect(() => {
    if (window.location.search) {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.size > 0) {
        const plan = queryParams.get("plan");
        const amount = queryParams.get("amount");
        const coupon = queryParams.get("couponCode");
        setQueryPackage({
          plan: plan,
          amount: amount,
          coupon: coupon,
        });
      }
    }
  }, []);

  useEffect(() => {
    const matchPackage = () => {
      if (queryPackage.plan && queryPackage.amount) {
        const matched = purlPackages.find(
          (pkg) =>
            pkg.plan === queryPackage.plan && pkg.amount === queryPackage.amount
        );
        if (matched) {
          setMatchedPackage(matched);
        } else {
          Toastr("Invalid package", "error");
          setMatchedPackage(null);
        }
      }
    };
    matchPackage();
  }, [queryPackage]);

  const history = useHistory();

  const handleLogin = () => {
    const { formIsValid, errors } = LoginFormValidation(user);
    setValidError(errors);
    if (formIsValid) {
      publicLogin(user, history);
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const clientName = window.location.href.split("/")[2].split(".")[0];

  const handleChange = (e) => {
    const { id, value } = e.target;
    setUser((current) => ({
      ...current,
      [id]: value,
    }));
  };

  const local_user = JSON.parse(localStorage.getItem("user"));
  if (local_user) {
    return <Redirect to="/links" />;
  }

  return (
    <div>
      <div style={{ alignItems: "flex-end" }}></div>
      <div className="text-center mt-4">
        <div className="text-center pb-0">
          <a
            href="https://www.p-url.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt={clientName}
              src={`https://storage.googleapis.com/purl-assets/client-logos/${clientName}.png`}
              width="150"
              height="90"
              style={{
                objectFit: "contain",
                maxWidth: "100%",
              }}
            />
          </a>
        </div>
        <p className="lead mt-3">Sign in to your account to continue</p>
      </div>
      <Card>
        <CardBody>
          <div className="m-sm-4">
            <Form>
              <FormGroup>
                <Label>
                  Email<span className="text-danger">*</span>
                </Label>
                <Input
                  bsSize="lg"
                  type="email"
                  name="email"
                  id="email"
                  value={user.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  autoComplete=""
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleLogin();
                    }
                  }}
                />
                <span style={{ color: "red" }}>
                  {!validError?.email && stateErrors?.vaidation === "email" && (
                    <p>*{stateErrors.message}</p>
                  )}
                  <p>{validError.email}</p>
                </span>
              </FormGroup>
              <FormGroup>
                <Label>
                  Password<span className="text-danger">*</span>
                </Label>
                <div className="d-flex">
                  <Input
                    style={{ paddingRight: "35px" }}
                    bsSize="lg"
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    id="password"
                    value={user.password}
                    onChange={handleChange}
                    placeholder="Enter your password"
                    autoComplete=""
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        handleLogin();
                      }
                    }}
                  />
                  {!passwordShown ? (
                    <Noeye onClick={togglePasswordVisiblity} />
                  ) : (
                    <Showeye onClick={togglePasswordVisiblity} />
                  )}
                </div>
                <span style={{ color: "red" }}>
                  {!validError?.password &&
                    stateErrors?.vaidation === "password" && (
                      <p>*{stateErrors.message}</p>
                    )}
                  <p>{validError.password}</p>
                </span>
                <h5>
                  <Link
                    to={() => {
                      if (queryPackage.coupon && queryPackage.plan) {
                        return `/auth/reset-link?plan=${queryPackage.plan}&amount=${queryPackage.amount}&couponCode=${queryPackage.coupon}`;
                      } else if (queryPackage.plan) {
                        return `/auth/reset-link?plan=${queryPackage.plan}&amount=${queryPackage.amount}`;
                      } else if (longUrl) {
                        return `/auth/reset-link?long_url=${longUrl}&link_name=${linkName}`;
                      } else {
                        return "/auth/reset-link";
                      }
                    }}
                  >
                    Forgot password?
                  </Link>
                </h5>
              </FormGroup>
              <div className="text-center mt-3 mb-4">
                <Button
                  color="primary"
                  size="lg"
                  onClick={handleLogin}
                  className="px-5"
                >
                  Sign in
                </Button>
              </div>
              <div>
                <h5 style={{ alignItems: "center", textAlign: "center" }}>
                  Don't have an account ?{" "}
                  <span>
                    <Text
                      className="primary-color"
                      onClick={() => {
                        if (queryPackage.coupon && queryPackage.plan) {
                          history.push(
                            `/auth/get-started?plan=${queryPackage.plan}&amount=${queryPackage.amount}&couponCode=${queryPackage.coupon}`
                          );
                        } else if (queryPackage.plan) {
                          history.push(
                            `/auth/get-started?plan=${queryPackage.plan}&amount=${queryPackage.amount}`
                          );
                        } else if (longUrl) {
                          history.push(
                            `/auth/get-started?long_url=${longUrl}&link_name=${linkName}`
                          );
                        } else {
                          history.push(`/auth/get-started`);
                        }
                      }}
                    >
                      Click Here
                    </Text>
                  </span>
                </h5>
              </div>
            </Form>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stateErrors: state.auth.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    publicLogin: (data, history) => dispatch(publicLogin(data, history)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PublicSignIn);
