import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { customStyles, errorStyles } from "../../../../components/CustomStyle";
import {
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  UncontrolledTooltip,
  CustomInput,
} from "reactstrap";
import DateTime from "react-datetime";
import { InfoCircleFill } from "@styled-icons/bootstrap/InfoCircleFill";
import { isValidPublicDomain } from "../../../../config";

const EditForm = ({
  campaign_name,
  error,
  short_domain,
  tag,
  tagOptions,
  handleTag,
  landing_page,
  utm_source,
  utmCampaign,
  utmContent,
  utmMedium,
  expiry,
  team,
  password,
  decryptedPassword,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div>
      <CardBody className="w-100">
        <Form id="formElem">
          <FormGroup>
            <Label className="one-rem-font-size">
              {isValidPublicDomain
                ? "Trackable Link Expiry"
                : "Campaign Expiry"}
              <span style={{ color: "#d9534f" }}>*</span>
            </Label>
            <Input
              className="custom-input"
              bsSize="lg"
              type="text"
              name="title"
              placeholder="Enter Campaign Name"
              value={campaign_name}
              disabled
            />
          </FormGroup>
          <FormGroup>
            <Label className="one-rem-font-size">
              Landing Page<span style={{ color: "#d9534f" }}>*</span>
            </Label>
            <Input
              className="custom-input"
              bsSize="lg"
              type="text"
              name="landing_page"
              id="landing_page"
              placeholder="Enter Landing Page"
              value={landing_page}
              disabled
            />
          </FormGroup>
          <FormGroup>
            <Label className="one-rem-font-size">
              Select Short Domain<span style={{ color: "#d9534f" }}>*</span>
            </Label>
            <div style={{ width: "100%" }}>
              <Select
                className="react-select-container w-100"
                placeholder="Select Domain..."
                value={short_domain}
                styles={customStyles}
                isDisabled
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label className="one-rem-font-size">
              {" "}
              {isValidPublicDomain
                ? "Trackable Link Expiry"
                : "Campaign Expiry"}
            </Label>
            {/* <CustomInput
              type="checkbox"
              label="Check the box to add an expiry date"
              className="d-flex flex-column mb-2"
              style={{ fontSize: "1rem" }}
              checked={checked}
              disabled
            /> */}
            <DateTime
              inputProps={{
                placeholder: "DD/MM/YYYY HH:MM A",
                className: "custom-input form-control form-control-lg",
                disabled: true,
                value: expiry,
                // style: error?.expiry ? { border: "1px solid red" } : {},
              }}
              dateFormat="DD/MM/YYYY"
            />
          </FormGroup>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <Label className="one-rem-font-size d-flex align-items-center">
                  <span>UTM Source</span>
                  <InfoCircleFill
                    size={16}
                    className="cursor-pointer primary-color ml-2"
                    id="sourceInfo"
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                  />
                  {isValidPublicDomain ? (
                    <UncontrolledTooltip placement="top" target="sourceInfo">
                      This is a required value when building tracking URLs to
                      attribute traffic to your trackable link and referred to
                      as utm_source in your URL. Please add where the trackable
                      link traffic will be directed to. Eg. If you are promoting
                      a website page, your source will be web.
                    </UncontrolledTooltip>
                  ) : (
                    <UncontrolledTooltip placement="top" target="sourceInfo">
                      This is a required value when building tracking URLs to
                      attribute traffic to your campaign and referred to as
                      utm_source in your URL. Please add where the campaign
                      traffic will be directed to. Eg. If you are promoting a
                      website page, your source will be web.
                    </UncontrolledTooltip>
                  )}
                </Label>
                <Input
                  className="custom-input"
                  bsSize="lg"
                  type="text"
                  name="utm_source"
                  id="utm_source"
                  value={utm_source.value}
                  placeholder="Enter UTM Source"
                  disabled
                  //   onChange={(e) => setUtmSource(e.target.value)}
                  //   required
                  //   style={error?.utmSource ? { border: "1px solid red" } : {}}
                />
                {/* <div
                  style={{
                    color: "red",
                    width: "100%",
                  }}
                >
                  <p>{error?.utmSource}</p>
                </div> */}
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Label className="one-rem-font-size">
                  <span>UTM Campaign</span>
                  <InfoCircleFill
                    size={16}
                    className="cursor-pointer primary-color ml-2"
                    id="campaignInfo"
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                  />
                  {isValidPublicDomain ? (
                    <UncontrolledTooltip placement="top" target="campaignInfo">
                      This is a required value when building tracking URLs to
                      attribute traffic to your trackable link and referred to
                      as utm_trackable link. Please add the name of your
                      trackable link.
                    </UncontrolledTooltip>
                  ) : (
                    <UncontrolledTooltip placement="top" target="campaignInfo">
                      This is a required value when building tracking URLs to
                      attribute traffic to your campaign and referred to as
                      utm_campaign. Please add the name of your campaign.
                    </UncontrolledTooltip>
                  )}
                </Label>
                <Input
                  className="custom-input"
                  bsSize="lg"
                  type="text"
                  name="utm_campaign"
                  id="utm_campaign"
                  value={utmCampaign.value}
                  placeholder="Enter UTM Campaign"
                  //   onChange={(e) => setUtmCampaign(e.target.value)}
                  disabled
                />
                {/* <div
                  style={{
                    color: "red",
                    width: "100%",
                  }}
                >
                  <p>{error?.utmCampaign}</p>
                </div> */}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <Label className="one-rem-font-size">
                  <span>UTM Content</span>
                  <InfoCircleFill
                    size={16}
                    className="cursor-pointer primary-color ml-2"
                    id="contentInfo"
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                  />
                  <UncontrolledTooltip placement="top" target="contentInfo">
                    Not a required element for tracking URLs, but great for
                    identifying how well specific elements in your content drive
                    clicks and conversions, (Eg. header-link, email-version).
                  </UncontrolledTooltip>
                </Label>
                <Input
                  className="custom-input"
                  bsSize="lg"
                  type="text"
                  name="utm_content"
                  id="utm_content"
                  value={utmContent.value}
                  placeholder="Enter UTM Content"
                  //   onChange={(e) => setUtmContent(e.target.value)}
                  //   required
                  disabled
                />
                {/* <div
                  style={{
                    color: "red",
                    width: "100%",
                  }}
                >
                  <p>{error?.utmContent}</p>
                </div> */}
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Label className="one-rem-font-size">
                  <span>UTM Medium</span>
                  <InfoCircleFill
                    size={16}
                    className="cursor-pointer primary-color ml-2"
                    id="mediumInfo"
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                  />
                  {isValidPublicDomain ? (
                    <UncontrolledTooltip placement="top" target="mediumInfo">
                      This is a required value when building tracking URLs to
                      attribute traffic to your trackable link and referred to
                      as utm_medium in your URL. Please add where how the
                      trackable link traffic is coming in (eg. paid-media or
                      owned-media).
                    </UncontrolledTooltip>
                  ) : (
                    <UncontrolledTooltip placement="top" target="mediumInfo">
                      This is a required value when building tracking URLs to
                      attribute traffic to your campaign and referred to as
                      utm_medium in your URL. Please add where how the campaign
                      traffic is coming in (eg. paid-media or owned-media).
                    </UncontrolledTooltip>
                  )}
                </Label>
                <Input
                  className="custom-input"
                  bsSize="lg"
                  type="text"
                  name="utm_medium"
                  id="utm_medium"
                  value={utmMedium.value}
                  placeholder="Enter UTM Medium"
                  //   onChange={(e) => setUtmMedium(e.target.value)}
                  //   required
                  disabled
                />
                {/* <div
                  style={{
                    color: "red",
                    width: "100%",
                  }}
                >
                  <p>{error?.utmMedium}</p>
                </div> */}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label className="one-rem-font-size">
                  Select Top Tags or Create Tags
                </Label>
                <div style={{ width: "100%" }}>
                  <CreatableSelect
                    isMulti
                    isClearable
                    onChange={handleTag}
                    options={tagOptions}
                    value={tag}
                    styles={error?.tag ? errorStyles : customStyles}
                    placeholder="Select Top Tags or Create Tags..."
                  />
                </div>
                <div
                  style={{
                    color: "red",
                    width: "100%",
                  }}
                >
                  <p>{error?.tag}</p>
                </div>
              </FormGroup>
              {!isValidPublicDomain && (
                <FormGroup>
                  <Label className="one-rem-font-size">Assign to Team</Label>
                  <div style={{ width: "100%" }}>
                    <Select
                      className="react-select-container w-100"
                      styles={customStyles}
                      value={team}
                      placeholder="Select A Team..."
                      isDisabled
                    />
                  </div>
                </FormGroup>
              )}
              <FormGroup>
                <Label className="one-rem-font-size">
                  <CustomInput
                    type="checkbox"
                    id="lockLink"
                    checked={password ? true : false}
                    label={<span>Lock Link</span>}
                  ></CustomInput>
                </Label>
                {password && (
                  <>
                    <div style={{ marginBottom: "0.5rem" }} className="d-flex">
                      <Input
                        bsSize="lg"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        value={decryptedPassword}
                        style={
                          error?.password ? { border: "1px solid red" } : {}
                        }
                        disabled
                      />
                    </div>

                    <div className="d-flex justify-content-start align-items-center">
                      <CustomInput
                        className="custom-control-label::before"
                        type="switch"
                        id="showPassword"
                        onChange={handleShowPassword}
                      ></CustomInput>
                      <label htmlFor="showPassword" className="m-0 mr-2">
                        Show Paswword
                      </label>
                    </div>
                  </>
                )}
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </div>
  );
};

export default EditForm;
