import React, { useState, useRef, useEffect } from "react";
import { Search } from "@styled-icons/boxicons-regular/Search";
import Select from "react-select";
import { connect } from "react-redux";
import { SquaredCross } from "@styled-icons/entypo/SquaredCross";
import {
  fetchSubscriberLists,
  getAllTag,
  downloadList,
  deleteSubscriberList,
  formSubmitSuccess,
  copyCampaign,
} from "../../../redux/actions/subscriberActions";
import Pagination from "../../../components/Pagination";
import {
  RefreshCw,
  CheckCircle,
  Clock,
  XCircle,
  Plus,
  Trash,
  BarChart2,
  Eye,
  Copy,
  Edit,
  Bell,
} from "react-feather";
import BootstrapTable from "react-bootstrap-table-next";
import { Lock } from "@styled-icons/boxicons-solid/Lock";
import { LockOpen } from "@styled-icons/boxicons-solid/LockOpen";
import { SimCardDownload } from "@styled-icons/material-outlined/SimCardDownload";
import { Download } from "@styled-icons/evaicons-solid/Download";
import { FileDownload } from "@styled-icons/remix-line/FileDownload";
import { Warning } from "@styled-icons/ionicons-solid/Warning";
import { useHistory } from "react-router-dom";
import { downloadRawClick } from "../../../redux/actions/reportAction";
import moment from "moment";
import styled from "styled-components";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Button,
  Spinner,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledAlert,
} from "reactstrap";
import { customStyles, Inputs } from "../../../components/CustomStyle";
import { isValidPublicDomain } from "../../../config";
const Bsearch = styled(Search)`
  position: absolute;
  color: hsl(0, 0%, 80%);
  width: 25px;
  height: 30px;
  left: 23px;
  padding-top: 7px;
  z-index: 1;
`;

const Cross = styled(SquaredCross)`
  position: absolute;
  color: hsl(0, 0%, 80%);
  width: 25px;
  height: 30px;
  right: 20px;
  top: 0;
  padding-top: 7px;
  z-index: 2;
  cursor: pointer;
`;

const SubscriberListNew = ({
  tagData,
  subscriberlist,
  getAllTag,
  fetchSubscriberLists,
  loading,
  error,
  subscriberLoadState,
  dataSucccess,
  deleteSubscriberList,
  deleteState,
  formSubmitSuccess,
  downloadRawClick,
  copyCampaignId,
  copyCampaign,
  userPackageExpiry,
}) => {
  const newPageTitle = "purl - Campaigns";
  document.title = newPageTitle;

  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.authorization.split("=")[1].split(";")[0];
  let userType = JSON.parse(atob(token.split(".")[1]));
  // const plan = userType?.plan;
  const plan = localStorage?.getItem("userPlan");

  const startDate = moment().subtract(6, "days").format("YYYY-MM-DD");
  const endDate = moment().format("YYYY-MM-DD");

  const [subListDeleteId, setSubListDeleteId] = useState("false");
  const [journeyCompleted, setJourneyCompleted] = useState(false);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const [subscriberData] = useState({
    campaign_name: "",
  });
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);

  const [input, setInput] = useState("");
  const inputFocus = useRef(subscriberData);
  const history = useHistory();
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchedPage, setSearchedPage] = useState(1);
  let [options] = useState([]);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const paginatedData = filteredData
    .slice(indexOfFirstPost, indexOfLastPost)
    .map((post) => {
      return {
        campaign_id: post?.campaign_id,
        campaign_name: post?.campaign_name,
        created_by: post?.user?.full_name
          ? post?.user.full_name
          : post?.user?.system_name,
        team: post?.Team?.team_name || "-",
        created_at: post?.createdAt,
        status: post?.status,
        purls: post?.shorturls,
        clicks: post?.clicks,
        short_domain_id: post?.short_domain_id,
        isFile: post?.files?.length,
        userActiveNotViewerInTeam: post?.user_active_not_viewer_in_team,
        password_protected: post?.password,
      };
    });
  const paginate = (number) => setCurrentPage(number);
  const filterbyTags = () => {
    setSearchedPage(currentPage);
    setCurrentPage(1);
    const filteredSubscriberData = subscriberlist.filter(
      (user) =>
        user?.campaign_name?.toLowerCase()?.includes(input.toLowerCase()) ||
        user?.user?.full_name?.toLowerCase()?.includes(input.toLowerCase()) ||
        user?.user?.system_name?.toLowerCase()?.includes(input.toLowerCase())
    );

    let array = [];
    selectedTags &&
      selectedTags.length > 0 &&
      filteredSubscriberData.forEach((item) => {
        item.subTagMappings.forEach((subTag) => {
          selectedTags.forEach((tag) => {
            if (subTag.tag.tag_name.toLowerCase() === tag.value.toLowerCase()) {
              array.push(item);
            }
          });
        });
      });

    const filteredAfterTag = [...new Set(array)];

    if (selectedTags && selectedTags.length > 0) {
      setFilteredData(filteredAfterTag);
    } else {
      if (input !== "") {
        setFilteredData(filteredSubscriberData);
      } else {
        setFilteredData(filteredData);
      }
    }
  };
  const filterbyTeams = () => {
    setSearchedPage(currentPage);
    setCurrentPage(1);
    const filteredSubscriberData = subscriberlist.filter(
      (user) =>
        user?.campaign_name?.toLowerCase()?.includes(input.toLowerCase()) ||
        user?.user?.full_name?.toLowerCase()?.includes(input.toLowerCase()) ||
        user?.user?.system_name?.toLowerCase()?.includes(input.toLowerCase())
    );

    let array = [];
    selectedTeams &&
      selectedTeams.length > 0 &&
      filteredSubscriberData.forEach((item) => {
        selectedTeams.forEach((team) => {
          if (
            item?.Team?.team_name.toLowerCase() === team?.label?.toLowerCase()
          ) {
            array.push(item);
          }
        });
      });

    const filteredAfterTeams = [...new Set(array)];

    if (selectedTeams && selectedTeams.length > 0) {
      setFilteredData(filteredAfterTeams);
    } else {
      if (input !== "") {
        setFilteredData(filteredSubscriberData);
      } else {
        setFilteredData(filteredData);
      }
    }
  };
  const setDropdown = () => {
    if (userType.role !== "Admin") {
      subscriberlist.forEach((ele) => {
        ele.subTagMappings.forEach((map) => {
          const val = options.findIndex((cl) => {
            if (cl.value === map.tag.tag_name) return true;
          });
          if (val < 0) {
            options.push({
              value: map.tag.tag_name,
              label: map.tag.tag_name,
            });
          }
        });
      });
    } else {
      for (let i = 0; tagData && i < tagData.length; i++) {
        options[i] = {
          value: tagData[i].tag_name,
          label: tagData[i].tag_name,
        };
      }
    }
  };
  const teamsOptions = subscriberlist
    ?.filter((item) => item?.Team !== null)
    .map((item) => ({
      value: item?.Team?.team_id,
      label: item?.Team?.team_name,
    }));

  const Cros = () => {
    if (input !== "") {
      return (
        <Cross
          onClick={() => {
            setInput("");
            selectedTags?.length == 0 || selectedTags === null
              ? setCurrentPage(searchedPage)
              : filterbyTags();
            selectedTeams?.length == 0 || selectedTeams === null
              ? setCurrentPage(searchedPage)
              : filterbyTeams();
          }}
        />
      );
    } else {
      return null;
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const tableColumns = [
    {
      dataField: "userActiveNotViewerInTeam",
      hidden: true,
    },
    {
      dataField: "password_protected",
      text: "Locked",
      headerStyle: { minWidth: "80px", whiteSpace: "nowrap" },
      style: { minWidth: "80px" },
      formatter: (cell, row) => {
        return (
          <>
            <div className="d-flex align-items-center justify-content-center">
              {row?.password_protected !== null ? (
                <label className="m-0" title="Locked">
                  <Lock size={22} />
                </label>
              ) : (
                <label className="m-0" title="Unlocked">
                  <LockOpen size={22} />
                </label>
              )}
            </div>
          </>
        );
      },
    },
    {
      dataField: "campaign_name",
      text: "Name",
      sort: true,
      headerStyle: { minWidth: "180px" },
      style: {
        width: "180px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
    {
      dataField: "created_by",
      text: "Created By",
      sort: true,
      headerStyle: { minWidth: "180px" },
      style: { minWidth: "180px" },
      hidden: isValidPublicDomain ? true : false,
    },
    {
      dataField: "team",
      text: "Team",
      sort: true,
      headerStyle: { minWidth: "180px" },
      style: { minWidth: "180px" },
      hidden: isValidPublicDomain ? true : false,
    },
    {
      dataField: "created_at",
      text: "Created On",
      sort: true,
      headerStyle: { minWidth: "200px" },
      style: { minWidth: "200px" },
      formatter: (cell) => {
        const date = new Date(cell).toLocaleString();
        return date;
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerStyle: { minWidth: "140px" },
      style: { minWidth: "140px" },
      formatter: (cell) => {
        return (
          <>
            {cell === "Pending" && (
              <div
                className="d-flex align-items-center"
                style={{ color: "#1F9BCF" }}
              >
                <Clock size={15} />
                <span className="ml-2">Pending</span>
              </div>
            )}
            {cell === "draft" && (
              <div
                className="d-flex align-items-center"
                style={{ color: "#f0ad4e" }}
              >
                <span className="ml-2">Draft</span>
              </div>
            )}
            {cell === "Processing" && (
              <div className="d-flex align-items-center">
                <div>
                  <Spinner
                    animation="border"
                    color="warning"
                    role="status"
                    size="sm"
                  />
                </div>
                <div className="ml-2" style={{ color: "#f0ad4e" }}>
                  Processing
                </div>
              </div>
            )}
            {cell === "Error" && (
              <div
                className="d-flex align-items-center"
                style={{ color: "#d9534f" }}
              >
                <XCircle size={15} />
                <span className="ml-2">Error</span>
              </div>
            )}
            {cell === "Complete" && (
              <div
                className="d-flex align-items-center"
                style={{ color: "#008000" }}
              >
                <CheckCircle size={15} />
                <span className="ml-1"> Complete</span>
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: "purls",
      text: "purls",
      sort: true,
      headerStyle: { minWidth: "80px" },
      style: { minWidth: "80px" },
      formatter: (cell, row) => {
        if (row.status === "draft" && cell === 0) {
          return "-";
        } else {
          return row?.purls !== undefined ? row?.purls : "-";
        }
      },
    },
    {
      dataField: "clicks",
      text: "Clicks",
      sort: true,
      headerStyle: { minWidth: "80px" },
      style: { minWidth: "80px" },
      formatter: (cell, row) => {
        if (row.status === "draft" && cell === 0) {
          return "-";
        } else {
          return row?.clicks !== undefined ? row?.clicks : "-";
        }
      },
    },
    {
      dataField: "viewReport",
      text: "View Report",
      headerStyle: { minWidth: "80px", whiteSpace: "nowrap" },
      style: { minWidth: "80px" },
      formatter: (cell, row) => {
        return (
          <>
            <div className="d-flex align-items-center justify-content-center">
              {row?.status === "Complete" ? (
                <label className="m-0" title="View Report">
                  <Eye
                    size={22}
                    className="primary-color cursor-pointer"
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                    onClick={() => {
                      history.push(`/campaigns/report/${row.campaign_id}`);
                    }}
                  />
                </label>
              ) : (
                <label className="m-0" title="Report not available">
                  <Eye
                    size={22}
                    color="#6c757d"
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                  />
                </label>
              )}
            </div>
          </>
        );
      },
    },
    {
      dataField: "Actions",
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {row?.status === "draft" ? null : (
              <div>
                {row?.status === "Complete" ? (
                  <label className="m-0" title="Download Clicks Report">
                    <FileDownload
                      size={22}
                      className="primary-color cursor-pointer"
                      id="Report"
                      style={{
                        border: "none",
                        outline: "none",
                      }}
                      onClick={() => {
                        downloadRawClick(
                          "no_range",
                          row?.campaign_name,
                          row?.campaign_id,
                          row?.short_domain_id,
                          startDate,
                          endDate
                        );
                      }}
                    />
                  </label>
                ) : (
                  <label
                    className="m-0"
                    title="Clicks Report not yet available"
                  >
                    <FileDownload
                      size={22}
                      color="#6c757d"
                      id="disabledReport"
                      style={{
                        border: "none",
                        outline: "none",
                      }}
                    />
                  </label>
                )}
              </div>
            )}
            {row?.isFile > 0 && (
              <div className="ml-2">
                <Download
                  size={22}
                  className="primary-color cursor-pointer"
                  id="Original"
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                  onClick={async () => {
                    await downloadList(
                      row?.campaign_id,
                      "input",
                      row?.campaign_name
                    );
                  }}
                  title={
                    isValidPublicDomain
                      ? "Download Original Report"
                      : "Download Original Campaign"
                  }
                />
              </div>
            )}
            {row?.status === "draft" ? null : (
              <div className="ml-2">
                {row?.status === "Complete" ? (
                  <>
                    <SimCardDownload
                      size={22}
                      className="primary-color cursor-pointer"
                      id="Processed"
                      style={{
                        border: "none",
                        outline: "none",
                      }}
                      onClick={async () => {
                        await downloadList(
                          row?.campaign_id,
                          "output",
                          row?.campaign_name
                        );
                      }}
                      title={
                        isValidPublicDomain
                          ? "Download Processed Report"
                          : "Download Processed Campaign"
                      }
                    />
                  </>
                ) : (
                  <>
                    <SimCardDownload
                      size={22}
                      color="#6c757d"
                      id="disabledProcessed"
                      style={{
                        border: "none",
                        outline: "none",
                      }}
                      title={
                        isValidPublicDomain
                          ? "Processed Report not available"
                          : "Processed Campaign not available"
                      }
                    />
                  </>
                )}
              </div>
            )}
            {row?.status === "draft" ? null : (
              <div className="ml-2">
                {row?.status === "Complete" &&
                (row?.team === "-" ||
                  row?.userActiveNotViewerInTeam === true) ? (
                  <label className="m-0" title="Add data">
                    <Plus
                      size={22}
                      className="cursor-pointer"
                      color="#f0ad4e"
                      id="addData"
                      style={{
                        border: "none",
                        outline: "none",
                      }}
                      onClick={() =>
                        history.push(`/campaigns/edit-list/${row.campaign_id}`)
                      }
                    />
                  </label>
                ) : (
                  <Plus
                    size={22}
                    className="cursor-pointer"
                    color="#6c757d"
                    id="disabledEdit"
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                    title="Edit is not available"
                  />
                )}
              </div>
            )}

            {row?.status === "draft" ? null : (
              <div className="ml-2">
                {row?.status === "Complete" &&
                (row?.team === "-" ||
                  row?.userActiveNotViewerInTeam === true) ? (
                  <label className="m-0" title="Copy">
                    <Copy
                      size={22}
                      className="cursor-pointer"
                      color="#f0ad4e"
                      id="Copy"
                      style={{
                        border: "none",
                        outline: "none",
                      }}
                      onClick={async () => {
                        await copyCampaign(row?.campaign_id);
                        setJourneyCompleted(true);
                      }}
                    />
                  </label>
                ) : (
                  <>
                    <Copy
                      size={22}
                      className="cursor-pointer"
                      color="#6c757d"
                      id="disabledCopy"
                      style={{
                        border: "none",
                        outline: "none",
                      }}
                      title="Copy is not available"
                    />
                  </>
                )}
              </div>
            )}
            {row?.status === "draft" &&
            (row?.team === "-" || row?.userActiveNotViewerInTeam === true) ? (
              <div className="ml-2">
                <label className="m-0" title="Edit Draft">
                  <Edit
                    size={18}
                    className="cursor-pointer"
                    color="#f0ad4e"
                    id="delete"
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                    onClick={() => {
                      history.push(`/campaigns/draft/${row?.campaign_id}`);
                    }}
                  />
                </label>
              </div>
            ) : null}
            <div className="ml-2">
              <label
                className="m-0"
                title={
                  isValidPublicDomain
                    ? "Delete Trackable link"
                    : "Delete Campaign"
                }
              >
                <Trash
                  size={18}
                  className="cursor-pointer"
                  color="#d9534f"
                  id="delete"
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                  onClick={() => {
                    toggleModal();
                    setSubListDeleteId(row?.campaign_id);
                  }}
                />
              </label>
            </div>
          </div>
        );
      },
      headerStyle: { minWidth: "170px" },
      style: { minWidth: "170px" },
    },
  ];

  // ----- Use Effect Section -------
  React.useEffect(() => {
    setFilteredData(subscriberlist);
    setDropdown();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberlist]);

  React.useEffect(() => {
    formSubmitSuccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      input === "" &&
      (selectedTags?.length === 0 ||
        selectedTags === null ||
        selectedTeams?.length === 0 ||
        selectedTeams === null)
    ) {
      setFilteredData(subscriberlist);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, selectedTags, selectedTeams]);

  React.useEffect(() => {
    if (input === "") {
      setCurrentPage(searchedPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  React.useEffect(() => {
    fetchSubscriberLists("false");
    getAllTag();
  }, [
    dataSucccess,
    deleteState,
    subscriberLoadState,
    fetchSubscriberLists,
    getAllTag,
  ]);

  React.useEffect(() => {
    if (copyCampaignId.length > 0 && journeyCompleted) {
      history.push(`/campaigns/draft/${copyCampaignId[0]}`);
    }
    return () => {
      setJourneyCompleted(false);
    };
  }, [copyCampaignId?.length, journeyCompleted]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedTeams?.length > 0) {
      filterbyTeams();
    } else {
      filterbyTags();
    }
  };

  return (
    <Container fluid className="p-0 mt-7 mt-lg-0">
      {userPackageExpiry === true && plan !== "shellpackage" && (
        <UncontrolledAlert
          color="primary"
          className="alert-outline-coloured w-100 mb-3"
        >
          <div className="alert-icon d-flex align-items-center">
            <Bell size={22} />
          </div>
          <div className="alert-message alert-message-components">
            <p className="mb-0 ">
              Your plan is expiring in 3 days.
              <a
                className="ml-lg-1 ml-0"
                href="https://www.p-url.com/pricing"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-nowrap d-none d-sm-inline-block">
                  Know more about pricing
                </span>
                <span className="text-nowrap d-inline-block d-sm-none">
                  Take action
                </span>
              </a>
            </p>
          </div>
        </UncontrolledAlert>
      )}
      {plan === "shellpackage" && (
        <UncontrolledAlert
          color="primary"
          className="alert-outline-coloured w-100 mb-3"
        >
          <div className="alert-icon d-flex align-items-center">
            <Bell size={22} />
          </div>
          <div className="alert-message alert-message-components">
            <p className="mb-0 ">
              To access create purls feature kindly upgrade your plan.
              <a
                className="ml-lg-1 ml-0"
                href="https://www.p-url.com/pricing"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-nowrap d-none d-sm-inline-block">
                  Click here to upgrade your plan.
                </span>
                <span className="text-nowrap d-inline-block d-sm-none">
                  Take action
                </span>
              </a>
            </p>
          </div>
        </UncontrolledAlert>
      )}
      <Row className="mb-2 mb-xl-4">
        <Col xs="auto" className="">
          <h1 className="h1 mb-3 page-headers-1 text-dark">
            {" "}
            {isValidPublicDomain ? "Trackable Links" : "Campaigns"}
          </h1>
        </Col>
        <Col xs="auto" className="ml-auto text-right mt-n1">
          <Button color="primary" className="shadow-sm" onClick={refreshPage}>
            <RefreshCw className="feather" />
          </Button>
        </Col>
      </Row>
      {loading ? (
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "200px" }}
        >
          <Spinner
            animation="border"
            color="primary"
            role="status"
            style={{ width: "8rem", height: "8rem", borderWidth: "10px" }}
          />
        </div>
      ) : (
        <Card className="d-flex d-md-block flex-column-reverse">
          <CardHeader className="d-flex d-md-block flex-column-reverse">
            <div className="card-actions ml-md-2 float-md-right mt-2 mt-md-0">
              <Button
                color="primary"
                size="lg"
                className="bg-white text-primary border border-primary"
                style={{
                  fontSize: "19px",
                  color: "white",
                  width: "100%",
                }}
                onClick={() => window.open("https://docs.p-url.com/", "_blank")}
              >
                Support Hub
              </Button>
            </div>
            <div className="card-actions float-md-right mt-2 mt-md-0">
              <Button
                color="primary"
                size="lg"
                href="/campaigns/create-purls"
                disabled={plan === "shellpackage"}
                style={{
                  fontSize: "19px",
                  color: "white",
                  width: "100%",
                  cursor: plan === "shellpackage" ? "not-allowed" : "pointer",
                }}
              >
                Create purls
              </Button>
            </div>
            <CardTitle tag="h5" className="pt-2 mb-0">
              Upload your data here to create{" "}
              {isValidPublicDomain ? "trackable links" : "campaign"} which you
              can use for personalization
            </CardTitle>
          </CardHeader>
          <div>
            <CardBody>
              <Bsearch />
              <form onSubmit={handleSubmit}>
                <div className="row mb-2">
                  <div
                    className={`${
                      isValidPublicDomain ? "col-md-6" : "col-md-4"
                    }  mb-2 mb-md-0`}
                  >
                    <Inputs
                      Focus={true}
                      onChange={(e) => {
                        setInput(e.target.value);
                      }}
                      ref={inputFocus}
                      value={input}
                      className="text-truncate"
                      placeholder={
                        isValidPublicDomain
                          ? "Search by trackable link name or uploaded by..."
                          : "Search by campaign name or uploaded by..."
                      }
                    />
                    <Cros />
                  </div>
                  <div
                    className={`${
                      isValidPublicDomain ? "col-md-4" : "col-md-3"
                    }  mb-2 mb-md-0`}
                  >
                    <Select
                      className="react-select-container w-100"
                      styles={customStyles}
                      placeholder="Select Tags..."
                      options={options}
                      onChange={(e) => {
                        setSelectedTags(e);
                      }}
                      isMulti
                    />
                  </div>
                  {!isValidPublicDomain && (
                    <div className="col-md-3 mb-2 mb-md-0">
                      <Select
                        className="react-select-container w-100"
                        styles={customStyles}
                        placeholder="Select Teams..."
                        options={teamsOptions}
                        onChange={(e) => {
                          setSelectedTeams(e);
                        }}
                        isMulti
                      />
                    </div>
                  )}

                  <div className="col-md-2">
                    <Button className="h-10 w-100" size="lg" type="submit">
                      Search
                    </Button>
                  </div>
                </div>
              </form>
              {paginatedData?.length > 0 ? (
                <div className="overflow-auto mt-4">
                  <BootstrapTable
                    bootstrap4
                    bordered={true}
                    striped={true}
                    keyField="name"
                    data={paginatedData}
                    columns={tableColumns}
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center my-5">
                  {input === "" && selectedTags?.length === 0 ? (
                    <h4 className="text-center">
                      {isValidPublicDomain
                        ? "No trackable links found. Please create new links."
                        : "No campaign found. Please create a new campaign."}
                    </h4>
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <Warning size={50} />
                      <h4 className="text-center">
                        Sorry, no results match your search parameters. Please
                        try again with different keywords.
                      </h4>
                    </div>
                  )}
                </div>
              )}
            </CardBody>
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={filteredData.length}
              paginate={paginate}
              currentPage={currentPage}
              setPostPerPage={setPostPerPage}
              postsInCurrentPage={paginatedData.length}
            />
          </div>
        </Card>
      )}
      {error ? (
        <h3 style={{ display: "flex", justifyContent: "center" }}>
          There is a problem with the server, please try again later!
        </h3>
      ) : null}
      <Modal centered isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          <div style={{ fontSize: 18 }}>Delete Campaign</div>
        </ModalHeader>
        <ModalBody>
          <p className="text-center" style={{ fontSize: 16 }}>
            {!isValidPublicDomain
              ? "Are you sure you want to delete the campaign?"
              : "Are you sure you want to delete the trackable link?"}
          </p>
          <div className="text-center my-3">
            <Button
              color="danger"
              size="lg"
              onClick={() => {
                deleteSubscriberList(subListDeleteId);
                toggleModal();
              }}
            >
              Delete
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriberlist: state.subscriber.subscriberlist,
    tagData: state.subscriber.tag,
    loading: state.subscriber.loading,
    error: state.subscriber.error.errorState,
    subscriberLoadState: state.subscriber.subscriberLoadState,
    dataSucccess: state.subscriber.dataSucccess,
    deleteState: state.subscriber.deleteState,
    formSubmissionStatus: state.subscriber.formSubmissionStatus,
    copyCampaignId: state.subscriber.copyCampaignId,
    userPackageExpiry: state.subscriber.userPackageExpiry,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubscriberLists: (query) => dispatch(fetchSubscriberLists(query)),
    copyCampaign: (id) => dispatch(copyCampaign(id)),
    getAllTag: () => dispatch(getAllTag()),
    deleteSubscriberList: (id) => dispatch(deleteSubscriberList(id)),
    formSubmitSuccess: () => dispatch(formSubmitSuccess()),
    downloadRawClick: (
      type,
      name,
      subListId,
      short_domain_id,
      start_date,
      end_date
    ) =>
      dispatch(
        downloadRawClick(
          type,
          name,
          subListId,
          short_domain_id,
          start_date,
          end_date
        )
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubscriberListNew);
