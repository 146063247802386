import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { Eye, EyeSlash } from "@styled-icons/bootstrap";
import {
  Card,
  CardBody,
  Input,
  Form,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import {
  resetPassVerification,
  resetPassword,
} from "../../redux/actions/authActions";
import { ResetPasswordValidation } from "../validation/validation";

const Showeye = styled(Eye)`
  position: absolute;
  color: hsl(0, 0%, 80%);
  width: 25px;
  height: 30px;
  right: 50px;
  padding-top: 7px;
  z-index: 1;
`;
const Noeye = styled(EyeSlash)`
  position: absolute;
  color: hsl(0, 0%, 80%);
  width: 25px;
  height: 30px;
  right: 50px;
  padding-top: 7px;
  z-index: 1;
`;

const ResetPassword = ({
  resetPassVerification,
  reset_password_email,
  errorMessage,
}) => {
  const [pass, setPass] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [tok, setTok] = useState({
    token: "",
    email: "",
  });
  const [error, setError] = useState({
    confirmPassword: "",
    password: "",
  });
  const [passwordShownOne, setPasswordShownOne] = useState(false);
  const togglePasswordOneVisiblity = () => {
    setPasswordShownOne(passwordShownOne ? false : true);
  };

  const [passwordShownTwo, setPasswordShownTwo] = useState(false);

  const clientName = window.location.href.split("/")[2].split(".")[0];

  const togglePasswordTwoVisiblity = () => {
    setPasswordShownTwo(passwordShownTwo ? false : true);
  };

  const { token } = useParams();
  const handleChange = (e) => {
    const { id, value } = e.target;
    setPass((current) => ({
      ...current,
      [id]: value,
    }));
  };

  const location = useLocation();
  const history = useHistory();
  const handlePassword = (formIsValid) => {
    if (formIsValid) {
      resetPassword(tok.token, pass);
      history.push("/auth/sign-in");
    }
  };

  React.useLayoutEffect(() => {
    if (token === null || errorMessage !== "") {
      history.push("/auth/LinkExpired");
    } else {
      resetPassVerification(token);
    }
  }, [setTok, errorMessage, history, resetPassVerification, token]);
  tok.email = reset_password_email.email;
  pass.email = reset_password_email.email;
  tok.token = token;
  let arrPath = location.pathname.split("/");

  const newPageTitleOne = "purl - Reset Password";
  const newPageTitleTwo = "purl - Set New Password";
  if (arrPath[2] === "reset-password") {
    document.title = newPageTitleOne;
  } else {
    document.title = newPageTitleTwo;
  }

  return (
    <div>
      <div>
        <div style={{ alignItems: "flex-end" }}></div>
        <div className="text-center mt-4">
          <div className="text-center pb-0">
            <img
              alt={clientName}
              src={`https://storage.googleapis.com/purl-assets/client-logos/${clientName}.png`}
              width="150"
              height="90"
              style={{
                objectFit: "contain",
                maxWidth: "100%"
              }}
            />
          </div>
          {arrPath[2] === "reset-password" ? (
            <p className="lead">Reset your password</p>
          ) : (
            <p className="lead">Set a new password for your account</p>
          )}
        </div>
        <span style={{ color: "red" }}>
          <p>{error.confirmPassError}</p>
        </span>
        <Card>
          <CardBody>
            <div className="m-sm-4">
              <Form>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    bsSize="lg"
                    type="email"
                    name="email"
                    id="email"
                    value={tok.email}
                    // onChange={() => {}}
                    placeholder="Enter your email"
                    disabled
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Password</Label>
                  <div className="d-flex">
                    <Input
                      style={{ paddingRight: "35px" }}
                      bsSize="lg"
                      type={passwordShownOne ? "text" : "password"}
                      name="password"
                      id="password"
                      value={pass.password}
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter your password"
                    />
                    {!passwordShownOne ? (
                      <Noeye onClick={togglePasswordOneVisiblity} />
                    ) : (
                      <Showeye onClick={togglePasswordOneVisiblity} />
                    )}
                  </div>
                  <span style={{ color: "red" }}>
                    <p>{error.password}</p>
                  </span>
                </FormGroup>
                <FormGroup>
                  <Label>Confirm Password</Label>
                  <div className="d-flex">
                    <Input
                      style={{ paddingRight: "35px" }}
                      bsSize="lg"
                      type={passwordShownTwo ? "text" : "password"}
                      name="confirmPassword"
                      id="confirmPassword"
                      value={pass.confirmPassword}
                      onChange={(e) => handleChange(e)}
                      placeholder="Confirm Password"
                    />
                    {!passwordShownTwo ? (
                      <Noeye onClick={togglePasswordTwoVisiblity} />
                    ) : (
                      <Showeye onClick={togglePasswordTwoVisiblity} />
                    )}
                  </div>
                  <span style={{ color: "red" }}>
                    <p>{error.confirmPassword}</p>
                  </span>
                </FormGroup>
                <div className="text-center mt-3 mb-4">
                  {arrPath[2] === "reset-password" ? (
                    <Button
                      color="primary"
                      size="lg"
                      onClick={() => {
                        const { errors, formIsValid } =
                          ResetPasswordValidation(pass);
                        setError(errors);
                        handlePassword(formIsValid);
                      }}
                    >
                      Reset Password
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      size="lg"
                      onClick={() => {
                        const { errors, formIsValid } =
                          ResetPasswordValidation(pass);
                        setError(errors);
                        handlePassword(formIsValid);
                      }}
                    >
                      Set Password
                    </Button>
                  )}
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
const mapPropsToState = (state) => {
  return {
    reset_password_email: state.auth.reset_password,
    errorMessage: state.auth.error.errorMessage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetPassVerification: (data) => dispatch(resetPassVerification(data)),
  };
};
// export default ResetPassword;
export default connect(mapPropsToState, mapDispatchToProps)(ResetPassword);
