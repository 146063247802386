import React, { useState, useEffect } from "react";
import { Button, Card, Input } from "reactstrap";
import {
  Link,
  useHistory,
  Redirect,
} from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { jwtDecode } from "jwt-decode";
import InputMask from "react-input-mask";

import { otpVerification } from "../../redux/actions/authActions";

const OTP = ({ otpVerificationCall, firstTimeMfa }) => {
  const newPageTitle = "purl - OTP Verification";
  document.title = newPageTitle;

  // eslint-disable-next-line no-unused-vars
  const [otp, setOtp] = useState("");
  const [validOtp, setValidOtp] = useState(true);

  const clientName = window.location.href.split("/")[2].split(".")[0];
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const mfa_enabled = user?.mfa_enabled;
  const token = user?.authorization?.split("=")[1]?.split(";")[0];

  const decoded = token ? jwtDecode(token) : "";

  function onSubmit() {
    const refinedOtp = otp.replace(/[\s_]/g, "");
    const data = {
      OTP: refinedOtp,
    };

    if (refinedOtp?.length < 6) {
      setValidOtp(false);
    } else {
      setValidOtp(true);
      otpVerificationCall(data);
    }
  }

  useEffect(() => {
    setValidOtp(true);
  }, [otp]);

  if (!user) {
    return <Redirect to="/auth/sign-in" />;
  } else if (user && decoded?.otpVerified && decoded?.onboarding === false) {
    return <Redirect to="/onboarding/step1" />;
  } else if (user && decoded?.otpVerified && !firstTimeMfa) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div className="text-center">
      <div>
        <div className="text-center pb-0">
          <img
            alt={clientName}
            src={`https://storage.googleapis.com/purl-assets/client-logos/${clientName}.png`}
            width="150"
            height="90"
            style={{
              objectFit: "contain",
              maxWidth: "100%",
            }}
          />
        </div>
        <h3 className="my-3">Two Factor Authentication</h3>
      </div>
      <Card>
        <div className="px-4 py-5 d-flex flex-column">
          <h5 className="text-secondary mb-3 h4">
            Please enter the verification code from your authenticator app
          </h5>
          <h5 className="text-secondary mb-3 mt-2" style={{ fontSize: "1rem" }}>
            Verification Code
          </h5>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <InputMask
              mask="9 9 9 9 9 9"
              placeholder="_ _ _ _ _ _"
              className="otp-input"
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
              }}
            >
              {(inputProps) => <Input {...inputProps} type="tel" />}
            </InputMask>
          </div>
          {!validOtp ? (
            <span style={{ color: "red" }}>
              <p>Please enter a valid otp</p>
            </span>
          ) : null}

          <Button size="lg" color="primary my-3 align-self-center">
            <h4
              className="m-0 text-white"
              style={{ letterSpacing: "0.5px" }}
              onClick={onSubmit}
            >
              Verify
            </h4>
          </Button>
          {mfa_enabled ? null : (
            <Button
              className="px-3 align-self-start mb-0"
              color="primary"
              outline
              onClick={() => history.push("/auth/2fa-configuration")}
            >
              Back
            </Button>
          )}
          <div>
            <Link
              to="/auth/sign-in"
              className="mb-0 cursor-pointer hover:text-decoration-underline "
              onClick={() => {
                localStorage.removeItem("user");
              }}
            >
              Return to Sign-in page
            </Link>
          </div>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loginState: state.auth.loginState,
    firstTimeMfa: state.auth.firstTimeMfa,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    otpVerificationCall: (otpString) => dispatch(otpVerification(otpString)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OTP);
