import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  CardHeader,
  CardTitle,
  Label,
} from "reactstrap";

import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateRangePicker } from "react-date-range";
import { Calendar, RefreshCw } from "react-feather";

import ClickChart from "./ClickChart";
import Table from "./Table";
import CustomDonoutgraph from "./CustomDonoutgraph";
import RecentPerformance from "./RecentPerformance";
import PurlPerformanceChart from "./PurlPerformanceChart";
import CampaignComparisonBarChart from "./CampaignComparisonBarChart";
import { ButtonDropdown } from "../../../../components/CustomStyle";
import {
  singleCampaignData,
  fetchSingleReportData,
  refresh_data,
  getAllCampaignDropdownData,
} from "../../../../redux/actions/subscriberActions";

import { Calendar as CalendarIcon } from "@styled-icons/boxicons-regular/Calendar";
import { CalendarX as CalendarXIcon } from "@styled-icons/boxicons-solid/CalendarX";
import { Link as LinkIcon } from "@styled-icons/evaicons-solid/Link";
import { ReloadCircle as RetargetIcon } from "@styled-icons/ionicons-outline/ReloadCircle";
import { Team as TeamIcon } from "@styled-icons/remix-fill/Team";
import { isValidPublicDomain } from "../../../../config";
const ViewReport = ({
  fetchSingleReportData,
  singleCampaignHeaderData,
  singleReportData,
  singleReportChartData,
  refresh_data,
  refresh,
  getAllCampaignDropdownData,
  campaignDropdownData,
}) => {
  const { id } = useParams();
  useEffect(() => {
    singleCampaignHeaderData(id);
    getAllCampaignDropdownData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (singleReportData?.uploaded_by) {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = user && user.authorization.split("=")[1].split(";")[0];
      let userType = token && JSON.parse(atob(token.split(".")[1]));

      if (
        userType?.id !== singleReportData?.uploaded_by &&
        userType?.role !== "Admin" &&
        !isValidPublicDomain
      ) {
        history.push("/dashboard");
      } else if (
        user.user_id !== singleReportData?.uploaded_by &&
        isValidPublicDomain
      ) {
        history.push("/links");
      }
    }
  }, [singleReportData]);

  const history = useHistory();

  const [data, setData] = useState({});
  const [browserData, setBrowserData] = useState({});
  const [browserTableData, setBrowserTableData] = useState([]);
  const [osData, setOsData] = useState({});
  const [osTableData, setOsTableData] = useState({});
  const [regionData, setRegionData] = useState({});
  const [regionTableData, setRegionTableData] = useState({});
  const [countryData, setCountryData] = useState({});
  const [countryTableData, setCountryTableData] = useState({});
  const [date, setDate] = useState([
    {
      startDate: moment().subtract(6, "days").startOf("day").toDate(),
      endDate: moment().endOf("day").toDate(),
      key: "selection",
    },
  ]);

  const purlData = {
    clicks: [
      singleReportChartData?.shorturls,
      singleReportChartData?.unique_urls_clicked
        ? singleReportChartData?.unique_urls_clicked
        : 0,
    ],
    label: ["purls Created", "Unique Clicks"],
  };
  const stepSize = 5;

  const ClickData = {
    clicks: [
      singleReportChartData?.shorturls,
      singleReportChartData?.clicks,
      singleReportChartData?.unique_urls_clicked
        ? singleReportChartData?.unique_urls_clicked
        : 0,
    ],
    label: ["purls Created", "Total Clicks", "Unique Clicks"],
    uniqueClicks: singleReportChartData?.unique_urls_clicked
      ? singleReportChartData?.unique_urls_clicked
      : 0,
    unClickedPurls:
      singleReportChartData?.shorturls -
      (singleReportChartData?.unique_urls_clicked || 0),
  };

  const refreshPage = () => {
    refresh_data();
  };

  if (isNaN(Number(id))) {
    history.push("/report-not-available");
  }

  useEffect(() => {
    if (date[0]?.startDate && date[0]?.endDate) {
      fetchSingleReportData(date[0].startDate, date[0].endDate, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date[0].endDate, date[0].startDate]);

  useEffect(() => {
    setData(singleReportData);
  }, [singleReportData]);

  useEffect(() => {
    const osClicks = singleReportChartData?.os_mapping?.map((osItem) => {
      return osItem?.clicks;
    });
    const osLabels = singleReportChartData?.os_mapping?.map((osItem) => {
      return osItem?.os?.os_name;
    });
    const ostabledata = singleReportChartData?.os_mapping?.map((osItem) => {
      return {
        clicks: osItem?.clicks,
        name: osItem?.os?.os_name,
      };
    });
    setOsData({
      clicks: osClicks,
      label: osLabels,
    });
    setOsTableData(ostabledata);

    const browserClicks = singleReportChartData?.browser_mapping?.map(
      (browserItem) => {
        return browserItem?.clicks;
      }
    );
    const browserLabel = singleReportChartData?.browser_mapping?.map(
      (browserItem) => {
        return browserItem?.browser?.browser_name;
      }
    );
    const browsertabledata = singleReportChartData?.browser_mapping?.map(
      (browserItem) => {
        return {
          clicks: browserItem?.clicks,
          name: browserItem?.browser?.browser_name,
        };
      }
    );
    setBrowserData({
      clicks: browserClicks,
      label: browserLabel,
    });
    setBrowserTableData(browsertabledata);

    const regionClicks = singleReportChartData?.region_mapping?.map(
      (regionItem) => {
        return regionItem?.clicks;
      }
    );
    const regionLabel = singleReportChartData?.region_mapping?.map(
      (regionItem) => {
        return regionItem?.region?.region_name;
      }
    );
    const regiontabledata = singleReportChartData?.region_mapping?.map(
      (regionItem) => {
        return {
          clicks: regionItem?.clicks,
          name: regionItem?.region?.region_name,
        };
      }
    );
    setRegionData({
      clicks: regionClicks,
      label: regionLabel,
    });
    setRegionTableData(regiontabledata);

    const countryClicks = singleReportChartData?.country_mapping?.map(
      (countryItem) => {
        return countryItem?.clicks;
      }
    );
    const countryLabel = singleReportChartData?.country_mapping?.map(
      (countryItem) => {
        return countryItem?.country?.country_name;
      }
    );
    const countrytabledata = singleReportChartData?.country_mapping?.map(
      (countryItem) => {
        return {
          clicks: countryItem?.clicks,
          name: countryItem?.country?.country_name,
        };
      }
    );
    setCountryData({
      clicks: countryClicks,
      label: countryLabel,
    });
    setCountryTableData(countrytabledata);
  }, [singleReportChartData]);

  useEffect(() => {
    if (refresh) {
      fetchSingleReportData(date[0].startDate, date[0].endDate, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <div className="mt-7 mt-lg-0">
      {data ? (
        <>
          <Row className="align-items-center mb-2">
            <Col xs="auto">
              <h1 className="page-headers-1 mb-0 text-dark">
                {isValidPublicDomain
                  ? "Trackable links Report"
                  : "Campaign Report"}
              </h1>
            </Col>
            <Col xs="auto" className="ml-auto align-items-center text-right">
              <div className="d-flex align-items-center">
                <Button
                  color="primary"
                  className="shadow-sm d-flex justify-content-center py-2"
                  onClick={refreshPage}
                >
                  <RefreshCw className="feather" />
                </Button>
              </div>
            </Col>
          </Row>
          <div className="py-3">
            <Card
              className="rounded py-1 px-2 py-sm-3 px-sm-4"
              style={{ backgroundColor: "#add8e699" }}
            >
              <div>
                <CardTitle className="font-weight-bold mb-4 mt-2">
                  <span>
                    {isValidPublicDomain
                      ? "Trackable link Name"
                      : "Campaign Name"}
                  </span>
                  <h1 className="text-secondary page-headers-1">
                    <span style={{ color: "black" }}>
                      {data?.campaign_name}
                    </span>
                  </h1>
                </CardTitle>

                <CardTitle
                  tag="h5"
                  className="font-weight-bold mb-2 text-dark d-flex flex-sm-row flex-column"
                >
                  <span className="d-flex align-items-center mb-1 pr-1">
                    <CalendarIcon size={18} className="mr-1" />
                    {isValidPublicDomain
                      ? "Trackable link Creation Date -"
                      : "Campaign Creation Date -"}
                  </span>
                  <span
                    style={{ color: "black" }}
                    className="pl-4 pl-sm-1 page-headers-2"
                  >
                    {moment(data?.createdAt).format("DD MMMM YYYY")}
                  </span>
                </CardTitle>
                <CardTitle
                  style={{
                    wordBreak: "break-all",
                  }}
                  tag="h5"
                  className="font-weight-bold mb-2 text-dark d-flex flex-sm-row flex-column w-100"
                >
                  <span
                    style={{ minWidth: "140px" }}
                    className="d-flex  mb-1 pr-1"
                  >
                    <LinkIcon size={18} className="mr-1" />
                    Landing Page -
                  </span>
                  <span
                    style={{ color: "black" }}
                    className="pl-4 pl-sm-1 page-headers-2"
                  >
                    {data?.landing_page}
                  </span>
                </CardTitle>
                <CardTitle
                  tag="h5"
                  className="font-weight-bold mb-2 text-dark d-flex flex-sm-row flex-column"
                >
                  <span className="d-flex align-items-center mb-1 pr-1">
                    <CalendarXIcon size={18} className="mr-1" />
                    {isValidPublicDomain
                      ? "Trackable link Expiry Date -"
                      : "Campaign Expiry Date -"}
                  </span>
                  <span
                    style={{ color: "black" }}
                    className="pl-4 pl-sm-1 page-headers-2"
                  >
                    {moment(data?.shorturl_expiry_time).format("DD MMMM YYYY")}
                  </span>
                </CardTitle>
                {singleReportChartData?.retarget?.name && (
                  <CardTitle
                    tag="h5"
                    className="font-weight-bold mb-2 text-dark d-flex flex-sm-row flex-column"
                  >
                    (
                    <span className="d-flex align-items-center mb-1 pr-1">
                      <RetargetIcon size={18} className="mr-1" />
                      Retargeted From -
                    </span>
                    )
                    <span
                      style={{ color: "black" }}
                      className="pl-4 pl-sm-1 page-headers-2"
                    >
                      {singleReportChartData?.retarget?.name && (
                        <a
                          href={`/campaigns/report/${singleReportChartData?.retarget?.id}`}
                        >
                          {singleReportChartData?.retarget?.name}
                        </a>
                      )}
                    </span>
                  </CardTitle>
                )}
                {data?.Team?.team_name && (
                  <CardTitle
                    tag="h5"
                    className="font-weight-bold mb-2 text-dark d-flex flex-sm-row flex-column"
                  >
                    <span className="d-flex align-items-center mb-1 pr-1">
                      <TeamIcon size={18} className="mr-1" />
                      Team Name -
                    </span>
                    <span
                      style={{ color: "black" }}
                      className="pl-4 pl-sm-1 page-headers-2"
                    >
                      {data?.Team?.team_name}
                    </span>
                  </CardTitle>
                )}
              </div>
            </Card>
            <div className="mb-4 d-flex justify-content-end">
              <UncontrolledDropdown inNavbar className="d-inline d-sm-none">
                <span>
                  <ButtonDropdown>
                    <DropdownToggle
                      color="primary"
                      size="lg"
                      className="d-flex justify-content-center py-2"
                    >
                      <Calendar className="feather align-middle" />
                    </DropdownToggle>
                  </ButtonDropdown>
                </span>
                <DropdownMenu
                  right
                  className="px-4 pb-4 pt-0"
                  style={{ width: "300px" }}
                >
                  <CardHeader>
                    <CardTitle
                      tag="h5"
                      className="text-center mb-0 font-weight-bold text-nowrap"
                    >
                      Filter by Date
                    </CardTitle>
                  </CardHeader>
                  <Label>Select date range</Label>

                  <DatePicker
                    className="form-control form-control-lg w-100"
                    placeholderText="Select date range"
                    selectsRange
                    startDate={date[0]?.startDate}
                    endDate={date[0]?.endDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(item) => {
                      setDate((prevDate) => [
                        {
                          ...prevDate[0],
                          startDate: item[0]
                            ? moment(item[0]).startOf("day").toDate()
                            : null,
                          endDate: item[1]
                            ? moment(item[1]).endOf("day").toDate()
                            : null,
                        },
                        ...prevDate.slice(1),
                      ]);
                    }}
                  />
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown inNavbar className="d-none d-sm-inline">
                <span>
                  <ButtonDropdown>
                    <DropdownToggle
                      caret
                      color="light"
                      className="bg-white shadow-sm"
                    >
                      <Calendar className="feather align-middle mr-2" />
                      {"   "}
                      {moment(date[0].startDate).format("DD/MM/YYYY")} -{"  "}
                      {moment(date[0].endDate).format("DD/MM/YYYY")}
                    </DropdownToggle>
                  </ButtonDropdown>
                </span>
                <DropdownMenu right>
                  <DateRangePicker
                    ranges={date}
                    onChange={(item) => {
                      setDate((prev) => [
                        {
                          ...prev[0],
                          startDate: item.selection.startDate,
                          endDate: moment(item.selection.endDate)
                            .endOf("day")
                            .toDate(),
                        },
                      ]);
                    }}
                    editableDateInputs={true}
                    rangeColors={["#e70000"]}
                    color={"#e70000"}
                  />
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div>
              <Card>
                <Col md="12">
                  <CardTitle
                    tag="h5"
                    className="text-center my-3 font-weight-bold"
                  >
                    Performance Review
                  </CardTitle>
                </Col>
                <Row>
                  <Col sm="12" md="7">
                    <Col className="py-3">
                      <ClickChart
                        Data={ClickData}
                        singleCampaignExpiryDate={data?.shorturl_expiry_time}
                      />
                    </Col>
                    <Col className="py-3">
                      <RecentPerformance
                        date_array={singleReportChartData?.date_array}
                        click_array={singleReportChartData?.click_array}
                        step_size={stepSize}
                      />
                    </Col>
                  </Col>
                  <Col sm="12" md="5">
                    <Col className="py-3">
                      <PurlPerformanceChart purlData={purlData} />
                    </Col>
                    <Col className="py-3">
                      <CampaignComparisonBarChart
                        campaignName={data?.campaign_name}
                        campaignClicks={singleReportChartData?.clicks}
                        campaignShortUrls={singleReportChartData?.shorturls}
                        campaignDropdownData={campaignDropdownData}
                        campaignId={id}
                      />
                    </Col>
                  </Col>
                </Row>
              </Card>
              <Card>
                <Col>
                  <Row className="justify-content-center px-xl-6">
                    <Col md={12}>
                      <CardTitle
                        tag="h5"
                        className="text-center my-3 font-weight-bold"
                      >
                        Interactive Click Analysis
                      </CardTitle>
                    </Col>
                    <Col className="py-3" sm="6">
                      <CustomDonoutgraph
                        Data={browserData}
                        tabledata={browserTableData}
                        title="Browser"
                      />
                    </Col>
                    <Col className="py-3" sm="6">
                      <CustomDonoutgraph
                        Data={osData}
                        tabledata={osTableData}
                        title="Device OS"
                      />
                    </Col>
                    <Col className="py-3" sm="6">
                      <CustomDonoutgraph
                        Data={regionData}
                        tabledata={regionTableData}
                        title="Region"
                      />
                    </Col>
                    <Col className="py-3" sm="6">
                      <CustomDonoutgraph
                        Data={countryData}
                        tabledata={countryTableData}
                        title="Country"
                      />
                    </Col>
                  </Row>
                </Col>
              </Card>
              <div>
                <Table />
              </div>
            </div>
          </div>
        </>
      ) : (
        history.push("/report-not-available")
      )}
    </div>
  );
};
const mapPropsToState = (state) => {
  return {
    singleReportData: state.subscriber.singleReportData,
    singleReportChartData: state.subscriber.singleReportChartData,
    refresh: state.subscriber.refresh,
    campaignDropdownData: state.subscriber.campaignDropdownData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    singleCampaignHeaderData: (id) => dispatch(singleCampaignData(id)),
    fetchSingleReportData: (start_date, end_date, id) =>
      dispatch(fetchSingleReportData(start_date, end_date, id)),
    refresh_data: () => dispatch(refresh_data()),
    getAllCampaignDropdownData: () => dispatch(getAllCampaignDropdownData()),
  };
};

export default connect(mapPropsToState, mapDispatchToProps)(ViewReport);
