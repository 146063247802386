import axios from "axios";
import TagManager from "react-gtm-module";
import * as types from "../constants";
import Toastr from "../../pages/misc/Toaster";
import { BACKEND_ENDPOINT } from "../../config";

let temp_token;

export const login = (data) => {
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/auth/login`;
    axios
      .post(reqURL, data)
      .then(function (res) {
        dispatch({
          type: types.LOGIN,
          payload: res.data,
        });
      })
      .catch(function (err) {
        dispatch({
          type: types.SET_ERRORS,
        });

        if (err.response) {
          dispatch({
            type: types.SET_ERRORS_SUCCESS,
            payload: err.response.data.message,
          });
          Toastr(err.response.data.message, "error");
        }
      });
  };
};

export const requestAccount = (data) => {
  return (dispatch) => {
    axios
      .post(`${BACKEND_ENDPOINT}/user-request`, data)
      .then((res) => {
        dispatch({ type: types.REQUEST_ACCOUNT, payload: res.data });
        dispatch({ type: types.REQUEST_ACCOUNT_SUCCESS });
      })
      .catch(function (err) {
        dispatch({
          type: types.SET_ERRORS,
        });
        if (err.response) {
          dispatch({
            type: types.SET_ERRORS_SUCCESS,
            payload: err.response.data.message,
          });
          Toastr(err.response.data.message, "error");
        }
      });
  };
};

export const resetPassword = (token, data) => {
  const reqURL = `${BACKEND_ENDPOINT}/auth/resetPassword`;
  axios
    .post(reqURL, {
      token: token,
      email: data.email,
      password: data.password,
      confirm_password: data.confirmPassword,
    })
    .then(() => {
      localStorage.removeItem("resetToken");
      Toastr(
        "Your password has been successfully updated. Thank you.",
        "success"
      );
    })
    .catch((error) => {
      if (error.response) {
        Toastr(error.response.data.message, "error");
      }
    });
};

export const resetPassVerification = (token) => {
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/auth/verifyResetToken`;

    axios
      .post(reqURL, { token: token })
      .then((res) => {
        dispatch({ type: types.RESET_PASSWORD, payload: res.data.email });
      })
      .catch((err) => {
        dispatch({
          type: types.SET_ERRORS,
        });
        if (err.response) {
          dispatch({
            type: types.SET_ERRORS_SUCCESS,
            payload: err.response.data.message,
          });
        }
      });
  };
};

export const resetLink = (data) => {
  return () => {
    const reqURL = `${BACKEND_ENDPOINT}/auth/getResetToken`;
    axios
      .post(reqURL, { email: data })
      .then(function (res) {
        temp_token = res.data[0].reset_link;
        localStorage.setItem(
          "resetToken",
          JSON.stringify({ token: temp_token, email: data })
        );
        Toastr(
          "A reset password link has been sent to your email. Thank you.",
          "success"
        );
        return temp_token;
      })
      .catch(function (error) {
        if (error.response) {
          const err = error.response.data.message;
          Toastr(err, "error");
        }
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    const reqUrl = `${BACKEND_ENDPOINT}/auth/logout`;
    axios
      .post(reqUrl, null)
      .then(() => {
        dispatch({ type: types.LOGOUT });
        Toastr(
          "Your password has been successfully updated. Thank you.",
          "success"
        );
        dispatch({ type: types.LOGOUT_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: types.LOGOUT, payload: error });
      });
  };
};

export const mfa = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");

  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/mfa/register`;
    axios
      .post(reqURL, null, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.SECRET_KEY, payload: res.data });
        dispatch({ type: types.FIRST_TIME_MFA });
        dispatch({ type: types.SECRET_KEY_SUCCESS });
      })
      .catch((err) => {
        dispatch({
          type: types.SET_ERRORS,
        });
        if (err.response) {
          dispatch({
            type: types.SET_ERRORS_SUCCESS,
            payload: err.response.data.message,
          });
          Toastr(err.response.data.message, "error");
        }
      });
  };
};

export const otpVerification = (otp) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user?.authorization?.split("=");
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/mfa/verify`;
    axios
      .put(
        reqURL,
        { ...otp },
        {
          headers: {
            authorization: `Bearer ${authorization[1].split(";")[0]}`,
          },
        }
      )
      .then((res) => {
        dispatch({ type: types.OTP_VERIFY, payload: res.data });
        dispatch({ type: types.FIRST_TIME_MFA_SUCCESS });
        dispatch({
          type: types.LOGIN_SUCCESS,
        });
        Toastr("Logged in Successfully.", "success");
      })
      .catch((err) => {
        dispatch({
          type: types.SET_ERRORS,
        });
        if (err.response) {
          dispatch({
            type: types.SET_ERRORS_SUCCESS,
            payload: err.response.data.message,
          });
          Toastr(err.response.data.message, "error");
        }
      });
  };
};

export const onboarding = (data, step) => {
  const user = JSON?.parse(localStorage?.getItem("user"));
  const authorization = user?.authorization?.split("=");
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/client`;
    axios
      .put(
        reqURL,
        { ...data },
        {
          headers: {
            authorization: `Bearer ${authorization[1].split(";")[0]}`,
          },
        }
      )
      .then((res) => {
        if (step == "one") {
          dispatch({ type: types.STEP_ONE });
          dispatch({ type: types.STEP_ONE_FILLED });
          dispatch({ type: types.STEP_ONE_UNDONE });
        } else if (step == "three") {
          dispatch({ type: types.STEP_THREE, payload: res.data });
          dispatch({ type: types.STEP_THREE_SUCCESS });
          Toastr(
            "Your onboarding journey has been completed. Thank you",
            "success"
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: types.SET_ERRORS,
        });
        if (err.response) {
          dispatch({
            type: types.SET_ERRORS_SUCCESS,
            payload: err.response.data.message,
          });
          Toastr(err.response.data.message, "error");
        }
      });
  };
};

export const fetchFallbackUrlData = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user?.authorization?.split("=");
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/client/get`;
    axios
      .get(reqURL, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.FETCH_ONBOARDING_DATA, payload: res.data });
      })
      .catch((err) => {
        dispatch({
          type: types.SET_ERRORS,
        });
        if (err.response) {
          dispatch({
            type: types.SET_ERRORS_SUCCESS,
            payload: err.response.data.message,
          });
          Toastr(err.response.data.message, "error");
        }
      });
  };
};

export const userReqest = (userData) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user?.authorization?.split("=");
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/user`;
    axios
      .post(reqURL, userData, {
        headers: {
          authorization: `Bearer ${authorization[1].split(";")[0]}`,
        },
      })
      .then((res) => {
        dispatch({ type: types.STEP_TWO });
        dispatch({ type: types.STEP_TWO_UNDONE });
        Toastr(res?.data?.message, "success");
      })
      .catch((err) => {
        dispatch({
          type: types.SET_ERRORS,
        });
        if (err.response) {
          dispatch({
            type: types.SET_ERRORS_SUCCESS,
            payload: err.response.data.message,
          });
          if (err?.response?.data?.errors) {
            Toastr(err?.response?.data?.errors[0]?.msg, "error");
          } else {
            Toastr(err.response.data.message, "error");
          }
        }
      });
  };
};

export const GetStartedAction = (data, history, setStep) => {
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/auth/publicRegister`;
    axios
      .post(reqURL, data, {})
      .then((res) => {
        dispatch({
          type: types.GET_STARTED,
          payload: res.data,
        });

        if (res?.data?.payment_plan) {
          dispatch({
            type: types.SET_PURL_PACKAGE,
            payload: res?.data?.payment_plan,
          });
        }
        if (res?.data?.customer_portal_url) {
          dispatch({
            type: types.FREE_TRIAL_PROMO_CODE,
            payload: res?.data?.customer_portal_url,
          });
        }
        if (res?.data) {
          localStorage.removeItem("temp_data");
        }
        history.push("/links");
        Toastr(res?.data?.Message, "success");

        // ---------GTM Start----------
        const tagManagerArgs = {
          gtmId: "GTM-PSTCZS3",
          dataLayer: {
            event: "registration",
            userId: res?.data?.user_id,
          },
        };
        TagManager.initialize(tagManagerArgs);
        // --------- GTM End-------------
      })
      .catch(function (err) {
        Toastr(err.response.data.message, "error");
        // localStorage.removeItem("temp_data");
        // setStep(1);
      });
  };
};

export const emptyPurlPackage = () => {
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_PURL_PACKAGE,
      payload: null,
    });
  };
};

export const setLinkPopup = () => {
  return (dispatch) => {
    dispatch({
      type: types.LINK_POPUP,
    });
  };
};

export const publicLogin = (data, history) => {
  return (dispatch) => {
    const reqURL = `${BACKEND_ENDPOINT}/auth/login`;
    axios
      .post(reqURL, data)
      .then((res) => {
        dispatch({
          type: types.PUBLIC_LOGIN,
          payload: res.data,
        });
        if (res?.data?.payment_plan) {
          dispatch({
            type: types.SET_PURL_PACKAGE,
            payload: res?.data?.payment_plan,
          });
        }
        history.push("/links");
        Toastr(res?.data?.Message, "success");
        // ---------GTM Start----------
        const tagManagerArgs = {
          gtmId: "GTM-PSTCZS3",
          dataLayer: {
            event: "login",
            userId: res?.data?.user_id,
          },
        };
        TagManager.initialize(tagManagerArgs);
        // --------- GTM End-------------
      })
      .catch((err) => {
        if (err?.response?.data?.vaidation !== undefined) {
          dispatch({
            type: types.LOGIN_ERROR,
            payload: err?.response?.data,
          });
        } else {
          Toastr(err.response.data.message, "error");
        }
      });
  };
};
export const validateUserPayment = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (!user || !user.authorization) {
    Toastr("Authorization data is missing", "error");
    return;
  }

  const authToken = user.authorization.split("=")[1]?.split(";")[0];
  if (!authToken) {
    Toastr("Your login token is not found,Kindly login again.", "error");
    return;
  }

  const reqURL = `${BACKEND_ENDPOINT}/transaction`;
  const headers = {
    authorization: `Bearer ${authToken}`,
  };

  return async () => {
    try {
      await axios.post(reqURL, {}, { headers });
      return true;
    } catch (error) {
      Toastr(error.response.data.message, "error");
      return false;
    }
  };
};
export const setCouponCode = (couponCode) => {
  return (dispatch) => {
    dispatch({
      type: types.SET_COUPON_CODE,
      payload: couponCode,
    });
  };
};

export const emptyCouponCode = () => {
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_COUPON_CODE,
      payload: null,
    });
  };
};

export const redirectToPaymentPortal = (plan, coupon) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const authorization = user.authorization.split("=");
  return (dispatch) => {
    const reqUrl = `${BACKEND_ENDPOINT}/makePayment`;
    dispatch({
      type: types.REDIRECTION_LOADING,
      payload: true,
    });
    axios
      .post(
        reqUrl,
        { plan_name: plan, promo_code: coupon },
        {
          headers: {
            authorization: `Bearer ${authorization[1].split(";")[0]}`,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: types.REDIRECTION_LOADING,
          payload: false,
        });
        dispatch({
          type: types.PAYMENT_REDIRECTION_URL,
          payload: res?.data?.url,
        });
      })
      .catch((err) => {
        if (err.response) {
          Toastr(err.response.data.message, "error");
          dispatch({
            type: types.REDIRECTION_LOADING,
            payload: false,
          });
        }
      });
  };
};
export const upgradePlan = (plan, promoCode) => {
  return async (dispatch) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const authorization = user.authorization.split("=");
    const reqUrl = `${BACKEND_ENDPOINT}/upgrade`;

    try {
      const res = await axios.post(
        reqUrl,
        { plan_name: plan, promo_code: promoCode },
        {
          headers: {
            authorization: `Bearer ${authorization[1].split(";")[0]}`,
          },
        }
      );

      if (res.status === 200) {
        dispatch({
          type: types.PLAN_UPGRADED,
          payload: { planUpgraded: true },
        });
        Toastr("Plan upgraded successfully", "success");
      }
    } catch (err) {
      if (err.response) {
        dispatch({
          type: types.PLAN_UPGRADED,
          payload: { planUpgraded: false },
        });
        Toastr(err.response.data.message, "error");
      }
    }
  };
};
export const downgradePlan = (plan) => {
  return async (dispatch) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const authorization = user.authorization.split("=");
    const reqUrl = `${BACKEND_ENDPOINT}/downgrade`;

    try {
      const res = await axios.post(
        reqUrl,
        { plan_name: plan },
        {
          headers: {
            authorization: `Bearer ${authorization[1].split(";")[0]}`,
          },
        }
      );

      if (res.status === 200) {
        dispatch({
          type: types.PLAN_DOWNGRADED,
          payload: { planDowngraded: true },
        });
        Toastr("Plan downgraded successfully", "success");
      }
    } catch (err) {
      if (err.response) {
        dispatch({
          type: types.PLAN_DOWNGRADED,
          payload: { planDowngraded: false },
        });
        Toastr(err.response.data.message, "error");
      }
    }
  };
};

export const emptyPlanUpgraded = () => {
  return (dispatch) => {
    dispatch({
      type: types.PLAN_UPGRADED,
      payload: { planUpgraded: false },
    });
  };
};
export const emptyPlanDowngraded = () => {
  return (dispatch) => {
    dispatch({
      type: types.PLAN_DOWNGRADED,
      payload: { planDowngraded: false },
    });
  };
};

export const emptyFreeTrialPromoCode = () => {
  return (dispatch) => {
    dispatch({
      type: types.FREE_TRIAL_PROMO_CODE,
      payload: null,
    });
  };
};
