import * as types from "../constants";

const initialState = {
  userrequest: [],
  updateuserrequest: {
    user_request_id: null,
    full_name: null,
    email: null,
    phone: null,
    role_id: null,
    action: null,
  },
  loading: false,
  error: false,
  editrequest: false,
  editrequestData: "",
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.FETCH_USER_REQUEST:
      return {
        ...state,
        userrequest: actions.payload,
        loading: false, // #TODO: update as per response here
      };
    // case types.ACCEPT_USER_REQUEST:
    //   return {
    //     ...state,
    //     userrequest: actions.payload,
    //     loading: false, // #TODO: update as per response here
    //   };
    case types.LOAD_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.USER_REQUEST_ERROR:
      //
      return {
        ...state,
        loading: false,
        error: true,
      };
    // case types.ACCEPT_USER_REQUEST:
    //   return {
    //     ...state,
    //     updateuserrequest: actions.payload,
    //     editrequest: true,
    //   };
    case types.EDIT_USER_REQUEST_SUCCESS:
      return {
        ...state,
        editrequest: true,
        editrequestData: actions.payload,
      };
    default:
      return state;
  }
}
