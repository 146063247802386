// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

// Auth
export const LOGIN = "LOGIN";
export const SECRET_KEY = "SECRET_KEY";
export const SECRET_KEY_SUCCESS = "SECRET_KEY_SUCCESS";
export const OTP_VERIFY = "OTP_VERIFY";
export const FIRST_TIME_MFA = "FIRST_TIME_MFA";
export const FIRST_TIME_MFA_SUCCESS = "FIRST_TIME_MFA_SUCCESS";
export const SET_PURL_PACKAGE = "SET_PURL_PACKAGE";
export const REMOVE_PURL_PACKAGE = "REMOVE_PURL_PACKAGE";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SET_COUPON_CODE = "SET_COUPON_CODE";
export const REMOVE_COUPON_CODE = "REMOVE_COUPON_CODE";
export const REDIRECTION_LOADING = "REDIRECTION_LOADING";
export const PAYMENT_REDIRECTION_URL = "PAYMENT_REDIRECTION_URL";
export const PLAN_UPGRADED = "PLAN_UPGRADED";
export const PLAN_DOWNGRADED = "PLAN_DOWNGRADED";
export const FREE_TRIAL_PROMO_CODE = "FREE_TRIAL_PROMO_CODE";

// UserReqests
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const ACCEPT_USER_REQUEST = "ACCEPT_USER_REQUEST";
export const REJECT_USER_REQUEST = "REJECT_USER_REQUEST";
export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const USER_REQUEST_ERROR = "USER_REQUEST_ERROR";
export const EDIT_USER_REQUEST_SUCCESS = "EDIT_USER_REQUEST_SUCCESS";
export const REGENERATE_TOKEN = "REGENERATE_TOKEN";

//Role
export const FETCH_ROLE = "FETCH_ROLE";
export const LOAD_ROLE = "LOAD_ROLE";
export const ROLE_ERROR = "ROLE_ERROR";

export const REGISTER = "REGISTER";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_ERRORS = "SET_ERROR";
export const SET_ERRORS_SUCCESS = "SET_ERRORS_SUCCESS";
export const REQUEST_ACCOUNT = "REQUEST_ACCOUNT";
export const REQUEST_ACCOUNT_SUCCESS = "REQUEST_ACCOUNT_SUCCESS";
// export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

//Subscriber lists
export const FETCH_SUBSCRIBER_LIST = "FETCH_SUBSCRIBER_LIST";
export const FETCH_SUBSCRIBER_LIST_SUCCESS = "FETCH_SUBSCRIBER_LIST_SUCCESS";
export const LOAD_SUBSCRIBER_LIST = "LOAD_SUBSCRIBER_LIST";
export const SUBSCRIBER_LIST_ERROR = "SUBSCRIBER_LIST_ERROR";
export const DELETE_SUBSCRIBER_LIST = "DELETE_SUBSCRIBER_LIST";
export const DELETE_SUBSCRIBER_LIST_SUCCESS = "DELETE_SUBSCRIBER_LIST_SUCCESS";
export const REPORT_SUBSCRIBER_LIST_SUCCESS = "REPORT_SUBSCRIBER_LIST_SUCCESS";
export const REPORT_SUBSCRIBER_LIST = "REPORT_SUBSCRIBER_LIST";
export const FETCH_SINGLE_CAMPAIGN_DATA = "FETCH_SINGLE_CAMPAIGN_DATA";
export const SINGLE_REPORT_CHART_DATA = "SINGLE_REPORT_CHART_DATA";
export const FETCH_TABLE_DATA = "FETCH_TABLE_DATA";
export const FETCH_TABLE_DATA_LOADING = "FETCH_TABLE_DATA_LOADING";
export const SINGLE_CAMPAIGN_REFRESH_DATA = "SINGLE_CAMPAIGN_REFRESH_DATA";
export const SINGLE_CAMPAIGN_REFRESH_STOP = "SINGLE_CAMPAIGN_REFRESH_STOP";
export const FETCH_TEAMS_LIST = "FETCH_TEAMS_LISTS";
export const TABSTATES = "TABSTATES";
export const USER_PACKAGE_EXPIRY = "USER_PACKAGE_EXPIRY";

//Subscriber constants
export const ADD_NEW_SUBSCRIBER_LIST = "ADD_NEW_LIST";
export const ADD_NEW_SHORT_URL_SUCCESS = "ADD_NEW_SHORT_URL_SUCCESS";
export const SHORT_URL = "SHORT_URL";
export const LONG_URL = "LONG_URL";
export const OPEN_SHORT_URL_MODAL = "OPEN_SHORT_URL_MODAL";
export const OPEN_LINK_IN_BIO_MODAL = "OPEN_LINK_IN_BIO_MODAL";
export const LINK_IN_BIO = "LINK_IN_BIO";
export const ADD_NEW_LINK_IN_BIO_SUCCESS = "ADD_NEW_LINK_IN_BIO_SUCCESS";
export const ADD_NEW_DRAFT = "ADD_NEW_DRAFT";
export const UPDATE_A_DRAFT = "UPDATE_A_DRAFT";
export const ADD_NEW_SUBSCRIBER_LIST_SUCCESS = "ADD_NEW_LIST_SUCCESS";
export const GET_SUBSCRIBER_LIST = "GET_SUBSCRIBER_LIST";
export const GET_SUBSCRIBER_LIST_SUCCESS = "GET_SUBSCRIBER_LIST_SUCCESS";
export const EDIT_SUBSCRIBER_LIST = "EDIT_SUBSCRIBER_LIST";
export const EDIT_SUBSCRIBER_LIST_SUCCESS = "EDIT_SUBSCRIBER_LIST_SUCCESS";
export const FILE_UPLOAD_START = "FILE_UPLOAD_START";
export const GET_SIGNED_URLS = "GET_SIGNED_URLS";
export const EMPTY_SIGNED_URLS = "EMPTY_SIGNED_URLS";
export const GET_SIGNED_URLS_SUCCESS = "GET_SIGNED_URLS_SUCCESS";
export const UPLOAD_FILES = "UPLOAD_FILES";
export const UPLOAD_FILES_SUCCESS = "UPLOAD_FILES_SUCCESS";
export const UPLOAD_ERROR = "UPLOAD_ERROR";
export const UPLOAD_ERROR_SUCCESS = "UPLOAD_ERROR_SUCCESS";
export const FORM_SUBMIT_SUCCESS = "FORM_SUBMIT_SUCCESS";
export const RETARGET_CAMPAIGN = "RETARGET_CAMPAIGN";
export const RETARGET_CAMPAIGN_SUCCESS = "RETARGET_CAMPAIGN_SUCCESS";
export const RETARGET_CAMPAIGN_LOADER = "RETARGET_CAMPAIGN_LOADER";
export const RETARGET_CAMPAIGN_PARENT_ID = "RETARGET_CAMPAIGN_PARENT_ID";
export const COPY_CAMPAIGN_ID = "COPY_CAMPAIGN_ID";
export const SAVE_CAMPAIGN_STATUS = "SAVE_CAMPAIGN_STATUS";
export const CHECK_PACKAGE_VALIDITY = "CHECK_PACKAGE_VALIDITY";

//Short Domain
export const GET_SHORT_DOMAIN = "GET_SHORT_DOMAIN";
export const GET_SHORT_DOMAIN_SUCCESS = "GET_SHORT_DOMAIN_SUCCESS";

//Teams
export const GET_TEAMS = "GET_TEAMS";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";

//LOAD STATE

export const LOAD = "LOAD";

//Get TAGS
export const GET_ALL_TAGS = "GET_ALL_TAGS";

//User Constants
export const FETCH_USER = "FETCH_USER";
export const FETCH_SINGLE_USER = "FETCH_SINGLE_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_SYSTEM_USER = "CREATE_SYSTEM_USER";
export const CREATE_SYSTEM_USER_SUCCESS = "CREATE_SYSTEM_USER_SUCCESS";
export const LOAD_USER = "LOAD_USER";
export const USER_ERROR = "USER_ERROR";
export const MODAL_TOGGLE = "MODAL_TOGGLE";
export const TEAM_UPDATION_SUCCESS = "TEAM_UPDATION_SUCCESS";
export const TEAM_LOAD = "TEAM_LOAD";
export const LOAD_DOMAIN = "LOAD_DOMAIN";
export const FETCH_DOMAINS = "FETCH_DOMAINS";
export const FETCH_DOMAINS_NAME = "FETCH_DOMAINS_NAME";
export const DOMAIN_ERROR = "DOMAIN_ERROR";
export const ADD_DOMAIN_LOADING = "ADD_DOMAIN_LOADING";
export const DELETE_DOMAIN_SUCCESS = "DELETE_DOMAIN_SUCCESS";
export const DOMAIN_CREATION_SUCCESS = " DOMAIN_CREATION_SUCCESS";
export const SUBDOMAIN_ADDITION_ERROR = " SUBDOMAIN_ADDITION_ERROR";
export const FETCH_PROFILE_DATA = " FETCH_PROFILE_DATA";
export const ERROR_STATE = "ERROR_STATE";
export const NEW_TOKEN_DATA = "NEW_TOKEN_DATA";
export const SUBSCRIPTION_CANCELLED = "SUBSCRIPTION_CANCELLED";
export const FETCH_PRICING_TABLE_DATA = " FETCH_PRICING_TABLE_DATA";
export const PROMO_CODE_DATA = "PROMO_CODE_DATA";

//Graph
export const FETCH_REPORTS = "FETCH_REPORTS";
export const REPORTS_ERROR = "REPORTS_ERROR";
export const FETCH_REPORTS_DATE = "FETCH_REPORTS_DATE";
export const FETCH_REPORTS_DATE_SUCCESS = "FETCH_REPORTS_DATE_SUCCESS";
export const FETCH_GRAPH_DATA = "FETCH_GRAPH_DATA";

//Table & Piecharts
export const FETCH_REPORTS_DATA = "FETCH_REPORTS_DATA";
export const REPORTS_DATA_ERROR = "REPORTS_DATA_ERROR";
export const LOAD_REPORTS = "LOAD_REPORTS";
export const FETCH_LINKS_DATA = "FETCH_LINKS_DATA";

//Calender Data
export const FETCH_DATE = "FETCH_DATE";
export const FETCH_DATE_ERROR = "FETCH_DATE_ERROR";

//Reports filter
export const FETCH_FILTERED_DATA = "FETCH_FILTERED_DATA";
export const FETCH_FILTERED_DATA_ERROR = "FETCH_FILTERED_DATA_ERROR";
export const FILTERED_DATA_REFRESH = "FILTERED_DATA_REFRESH";
export const FETCH_FILTERED_DATA_BUTTON = "FETCH_FILTERED_DATA_BUTTON";
export const FETCH_TOPTEN_URLS = "FETCH_TOPTEN_URLS";
export const RESET_TOP_TEN = "RESET_TOP_TEN";
export const RESET_PAGE = "RESET_PAGE";

//Help and Support
export const NEW_HELP_REQUEST = "NEW_HELP_REQUEST";
export const NEW_HELP_REQUEST_SUCCESS = "NEW_HELP_REQUEST_SUCCESS";

//Onboarding
export const STEP_ONE = "STEP_ONE";
export const STEP_ONE_UNDONE = "STEP_ONE_UNDONE";
export const STEP_ONE_FILLED = "STEP_ONE_FILLED";
export const STEP_TWO = "STEP_TWO";
export const STEP_TWO_UNDONE = "STEP_TWO_UNDONE";
export const STEP_THREE = "STEP_THREE";
export const STEP_THREE_SUCCESS = "STEP_THREE_SUCCESS";
export const FETCH_ONBOARDING_DATA = "FETCH_ONBOARDING_DATA";

//Campaigns for dropdown
export const GET_CAMPAIGNS_DROPDOWN = "GET_CAMPAIGNS_DROPDOWN";
export const GET_CAMPAIGNS_DROPDOWN_SUCCESS = "GET_CAMPAIGNS_DROPDOWN_SUCCESS";

//Public Domain
export const GET_STARTED = "GET_STARTED";
export const SET_PUBLIC_ERROR = "SET_PUBLIC_ERROR ";
export const LINK_POPUP = "LINK_POPUP";
export const GET_TABLE_DATA = "GET_TABLE_DATA";
export const PUBLIC_LOGIN = "PUBLIC_LOGIN";

//Mini Links
export const MINI_LINKS_DATA = "MINI_LINKS_DATA";
